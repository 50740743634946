import { Controller } from '@hotwired/stimulus';

export default class CommonTabsFrameController extends Controller {
  static outlets = ['common--tabs--frame'];

  static values = { active: Boolean };

  activeValueChanged() {
    if (this.activeValue) {
      this.show();
    } else {
      this.hide();
    }
  }

  hideAllFrames() {
    this.commonTabsFrameOutlets.forEach((frame) => frame.hide());
  }

  show(frameId?: string) {
    // show frame if it has the same id as the param, or there are no params
    if (frameId) {
      if (frameId === '') {
        this.element.classList.remove('hidden');
      }
    } else {
      this.element.classList.remove('hidden');
    }
  }

  hide() {
    this.element.classList.add('hidden');
  }

  declare readonly commonTabsFrameOutlets: this[];
  declare activeValue: Boolean;
}
