import { Controller } from '@hotwired/stimulus';
import { useHotkeys } from 'stimulus-use/dist/hotkeys';
import {
  navigateToLink,
  getElementDistanceFromViewportBottom,
} from '../../../javascript/lib/utils';
export default class extends Controller {
  /*
   * Explanation of focusIndex, focusDepth, and groupIndex:
   *
   * - Focus Index: Position of an item within its group.
   * - Focus Depth: Hierarchical level of the group.
   * - Group Index: Position of the group within its parent group.
   */

  static targets = ['ariaLive', 'announcementText']

  static values = {
    focusIndex: Number, // Current focused item index.
    focusDepth: Number, // Current depth of focus.
    groupIndex: Number, // Current group index.
    totalItems: Number, // Total items in the current group.
    listId: String, // Unique ID for the list.
  };

  connect() {
    useHotkeys(this, {
      up: [this.handleUpArrowKey],
      down: [this.handleDownArrowKey],
      right: [this.handleRightArrowkey],
      left: [this.handleLeftArrowKey],
      enter: [this.handleEnterKey],
    });
  }

  /**
   * Handles the hover behavior for listbox items.
   * @param {MouseEvent} event - The mouseover event on the listbox item.
   * - Determines the new focus depth based on the item's data attribute.
   * - Updates the focus state accordingly.
   */
  handleHover(event: MouseEvent): void {
    const hoveredItem = event.target as HTMLElement | null;
    if (!hoveredItem) return;

    if (
      // Only update if the item is available and not selected.
      hoveredItem.getAttribute('data-unavailable') !== 'true' &&
      hoveredItem.getAttribute('aria-selected') !== 'true'
    ) {
      const newDepth = parseInt(hoveredItem.getAttribute('data-depth') || '0');

      if (this.focusDepthValue !== newDepth) {
        this.focusDepthValue = newDepth;
      }

      this.clearFocus(newDepth);
      this.updateFocus(hoveredItem, true, false, true);
    }
  }

  /**
   * Handles click events on listbox items.
   * @param {MouseEvent} event - The click event.
   * - Navigates to the link associated with the focused item if applicable.
   */
  handleClick(event: MouseEvent): void {
    const focusedElement: HTMLAnchorElement | null = this.getFocusedLink();
    const clickElement = event.target as HTMLElement | null;
    if (focusedElement && focusedElement.contains(clickElement)) {
      navigateToLink(focusedElement);
    }
  }

  /**
   * Handles the Enter key press event.
   * @param {KeyboardEvent} event - The keyboard event.
   * - Activates the focused link if it has an "href" attribute.
   */
  handleEnterKey(event: KeyboardEvent): void {
    const listbox = this.element;

    // Because there can be multiple nested listboxes on a page, we want to make sure we are targeting
    // the correct nested listbox. If no element is focused in the DOM, the keypress event returns the
    // body element by default, so we check for that too.
    // In the specific case that the nested listbox is the child of a button dropdown, we want to
    // ignore the keypress if the dropdown menu is not expanded.
    if (
      !document.activeElement?.contains(listbox) ||
      document.activeElement === document.body ||
      document.activeElement.getAttribute('data-is-expanded') === 'false'
    ) {
      return;
    }

    event.preventDefault();

    const focusedElement = this.getFocusedLink();
    if (focusedElement?.hasAttribute('href')) {
      navigateToLink(focusedElement);
    }
  }

  /**
   * Handles the Up Arrow key press event.
   * @param {KeyboardEvent} event - The keyboard event.
   * - Moves the focus to the previous item in the list if possible.
   */
  handleUpArrowKey(event: KeyboardEvent): void {
    const focusElement = event.target as HTMLElement | null;

    if (!focusElement) return;

    if (!focusElement.contains(this.element) || event.target === document.body) return;
    event.preventDefault();

    if (!isNaN(this.focusIndexValue) && this.focusIndexValue > 0) {
      this.focusIndexValue -= 1;
      this.updateFocusedElement();
    }
  }

  /**
   * Handles the Down Arrow key press event.
   * @param {KeyboardEvent} event - The keyboard event.
   * - Moves the focus to the next item in the list if possible.
   */
  handleDownArrowKey(event: KeyboardEvent): void {
    const focusElement = event.target as HTMLElement | null;

    if (!focusElement) return;

    if (!focusElement.contains(this.element) || event.target === document.body) return;
    event.preventDefault();

    if (!isNaN(this.focusIndexValue) && this.focusIndexValue < this.totalItemsValue - 1) {
      this.focusIndexValue += 1;
      this.updateFocusedElement();
    }
  }

  /**
   * Handles the Left Arrow key press event.
   * @param {KeyboardEvent} event - The keyboard event.
   * - Moves the focus to the parent group if applicable.
   */
  handleLeftArrowKey(event: KeyboardEvent): void {
    if (!this.isCurrentListbox()) return;
    event.preventDefault();

    if (this.focusDepthValue > 0) {
      this.focusDepthValue--;

      let focusItem = this.getFocusedElement();

      this.groupIndexValue = parseInt(focusItem?.getAttribute('data-parent-group-index') || '0');

      const focusGroup = this.getFocusedGroupElement(this.focusDepthValue);

      this.totalItemsValue = parseInt(focusGroup?.getAttribute('data-total-items') || '0');
      this.focusIndexValue = parseInt(focusGroup?.getAttribute('data-index') || '0');
      focusItem = this.getFocusedElement();

      if (focusItem) {
        const nestedItemGroup = focusItem.querySelector('.absolute');
        if (nestedItemGroup) nestedItemGroup.classList.add('hidden');
        const text = this.getAnnounceText(focusItem as HTMLElement);
        this.updateAriaLive(text);
      }
    }
  }

  /**
   * Handles the Right Arrow key press event.
   * @param {KeyboardEvent} event - The keyboard event.
   * - Expands and focuses on the nested group if applicable.
   */
  handleRightArrowkey(event: KeyboardEvent): void {
    if (!this.isCurrentListbox()) return;
    event.preventDefault();

    const focusedElement = this.getFocusedElement() as HTMLElement;
    if (focusedElement?.classList.contains('nested-listbox-item-has-items')) {
      this.openNestedGroup(focusedElement);
    }
  }

  /**
   * Checks if the current listbox.
   * @returns {boolean} - True if the listbox is active, false otherwise.
   */
  isCurrentListbox(): boolean | undefined {
    const listbox = this.element;
    // Because there can be multiple nested listboxes on a page, we want to make sure we are targeting
    // the correct nested listbox. If no element is focused in the DOM, the keypress event returns the
    // body element by default, so we check for that too.
    return (
      document.activeElement?.contains(listbox) &&
      document.activeElement !== document.body &&
      document.activeElement.getAttribute('data-is-expanded') !== 'false'
    );
  }

  /**
   * Opens the nested group of the given element.
   * @param {HTMLElement} element - The DOM element representing the parent of the nested group.
   */
  openNestedGroup(element: HTMLElement): void {
    const nestedGroup = element.querySelector('.absolute');
    if (nestedGroup) nestedGroup.classList.remove('hidden');

    this.focusDepthValue += 1;
    this.groupIndexValue = parseInt(element.getAttribute('data-group-index') || '0');

    const focusGroup = this.getGroupElement(this.focusDepthValue);
    this.focusIndexValue = parseInt(focusGroup?.getAttribute('data-index') || '0');
    this.totalItemsValue = parseInt(focusGroup?.getAttribute('data-total-items') || '0');

    const newFocus = this.getFocusedElement() as HTMLElement;
    if (newFocus) {
      this.updateFocus(newFocus, true, true);
      const text = this.getAnnounceText(newFocus);
      this.updateAriaLive(text);
    }
  }

  /**
   * Updates the focus to the currently highlighted element.
   */
  updateFocusedElement(): void {
    const query = `#${this.listIdValue} .nested-listbox-item-group-${this.focusDepthValue}-${this.groupIndexValue} .nested-listbox-item-${this.focusDepthValue}-${this.focusIndexValue}`;
    const newFocusElement = this.element.querySelector(query) as HTMLElement;

    if (newFocusElement) {
      this.clearFocus(this.focusDepthValue);
      this.updateFocus(newFocusElement, true, true, true);
      const text = this.getAnnounceText(newFocusElement);
      this.updateAriaLive(text);
    }
  }

  /**
   * Updates the content of the ARIA live region.
   * This function accepts a string and, if the ariaLive target element exists,
   * updates its textContent with the provided text. This allows screen readers
   * to announce the new content to users.
   * @param {string} text - The text to be announced via the ARIA live region.
   */
  updateAriaLive(text: string): void {
    if (this.hasAriaLiveTarget) {
      this.ariaLiveTarget.textContent = text;
    }
  }

  /**
   * Retrieves the announcement text from the highlighted DOM element.
   * This function searches for a child element with the class "announcement-text"
   * within the given element. If found, it returns its trimmed text content.
   * If no such child is present, it returns an empty string.
   * @param {HTMLElement|null} element - The DOM element currently highlighted by Arrow Key.
   */
  getAnnounceText(element: HTMLElement): string {
    for (const announcementEl of this.announcementTextTargets) {
      if (element.contains(announcementEl)) {
        return announcementEl.textContent?.trim() || '';
      }
    }
    return '';
  }

  /**
   * Updates the focus state of the specified element.
   * @param {HTMLElement|null} element - The DOM element to update focus on.
   * @param {boolean} isFocused - Whether the element should be focused.
   * @param {boolean} scroll - Whether to scroll to the element.
   * @param {boolean} toggleNested - Whether to toggle the nested group visibility.
   */
  updateFocus(
    element: HTMLElement | null,
    isFocused: boolean,
    scroll: boolean = false,
    toggleNested: boolean = false,
  ): void {
    // Set the element focus.
    if (!element) return;
    element.setAttribute('data-focused', String(isFocused));
    element.classList.toggle('is-focused', isFocused);
    element.classList.toggle('[&>*]:text-white', isFocused);
    element.classList.toggle('[&>*>svg]:text-white', isFocused);

    const dataIndex = element.getAttribute('data-index');

    if (dataIndex) this.focusIndexValue = parseInt(dataIndex);

    const groupIndex = parseInt(element?.getAttribute('data-parent-group-index') || '0');
    this.groupIndexValue = groupIndex;

    const focusGroup = this.getFocusedGroupElement(this.focusDepthValue);
    if (focusGroup) {
      focusGroup.setAttribute('data-index', String(this.focusIndexValue));
    }

    // Scroll the element into view if focused.
    if (scroll && isFocused) {
      element.scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'start' });
    }

    // If the element is focused and has nested items, make sure to show it and adjust the nested
    // dropdown to fit in the screen.
    const nestedItemGroup = element.querySelector('.absolute') as HTMLElement | null;
    if (element.classList.contains('nested-listbox-item-has-items')) {
      if (toggleNested && nestedItemGroup) {
        nestedItemGroup.classList.toggle('hidden', !isFocused);
      }

      if (isFocused && nestedItemGroup) {
        nestedItemGroup.style.transform = `translateY(-${0}px)`;
        if (isFocused) {
          const listItemDistanceFromTop = element.getBoundingClientRect().top;
          const groupDistanceFromTop = nestedItemGroup.getBoundingClientRect().top;
          const diff = groupDistanceFromTop - listItemDistanceFromTop;
          nestedItemGroup.style.transform = `translateY(-${diff}px)`;

          const distanceFromBottom = getElementDistanceFromViewportBottom(nestedItemGroup);
          if (distanceFromBottom < 0) {
            nestedItemGroup.style.transform = `translateY(-${diff + (-distanceFromBottom + 5)}px)`;
          }
        }
      }
    }
  }

  /**
   * Clears the focus state for all elements at the specified depth.
   * @param {number} depth - The depth level to clear focus for.
   */
  clearFocus(depth): void {
    const query = `#${this.listIdValue} .nested-listbox-item-group-${depth} .nested-listbox-item`;
    this.element
      .querySelectorAll(query)
      .forEach((item) => this.updateFocus(item as HTMLElement, false, false, true));
  }

  /**
   * Updates the group focus when navigating between groups.
   * @param {number} direction - The direction to navigate (-1 for parent, 1 for child).
   */
  updateGroupFocus(direction): void {
    const currentItem = this.getFocusedElement();

    this.groupIndexValue = parseInt(currentItem?.getAttribute('data-parent-group-index') || '0');
    const group = this.getGroupElement(this.focusDepthValue);

    if (group) {
      this.totalItemsValue = parseInt(group.getAttribute('data-total-items') || '0');
      this.focusIndexValue = parseInt(group.getAttribute('data-index') || '0');
    }

    if (direction === -1) {
      const nestedGroup = currentItem?.querySelector('.absolute');
      if (nestedGroup) nestedGroup.classList.add('hidden');
    }
  }

  /**
   * Retrieves the currently focused link element.
   * @returns {HTMLAnchorElement|null} - The focused link element or null if none.
   */
  getFocusedLink(): HTMLAnchorElement | null {
    const query = `#${this.listIdValue} .nested-listbox-item-group-${this.focusDepthValue}-${this.groupIndexValue} .nested-listbox-item-${this.focusDepthValue}-${this.focusIndexValue} a`;
    return this.context.element.querySelector(query) as HTMLAnchorElement;
  }

  /**
   * Retrieves the currently focused item element.
   * @returns {Element|null} - The focused item element or null if none.
   */
  getFocusedElement(): Element | null {
    const queryString = `#${this.listIdValue} .nested-listbox-item-group-${this.focusDepthValue}-${this.groupIndexValue} .nested-listbox-item-${this.focusDepthValue}-${this.focusIndexValue}`;
    return this.context.element.querySelector(queryString);
  }

  /**
   * Retrieves the group element for the specified depth.
   * @param {number} depth - The depth level to retrieve the group for.
   * @returns {Element|null} - The group element or null if none.
   */
  getFocusedGroupElement(depth: number): Element | null {
    const queryString = `#${this.listIdValue} .nested-listbox-item-group-${depth}-${this.groupIndexValue}`;
    return this.context.element.querySelector(queryString);
  }

  /**
   * Retrieves the group element for the specified depth.
   * @param {number} depth - The depth level to retrieve the group for.
   * @returns {Element|null} - The group element or null if none.
   */
  getGroupElement(depth: number): Element | null {
    const query = `#${this.listIdValue} .nested-listbox-item-group-${depth}-${this.groupIndexValue}`;
    return this.element.querySelector(query);
  }

  declare listIdValue: string;
  declare focusIndexValue: number;
  declare focusDepthValue: number;
  declare groupIndexValue: number;
  declare hotkeyOptions: any;
  declare totalItemsValue: number;
  declare readonly ariaLiveTarget: HTMLElement;
  declare readonly announcementTextTargets: HTMLElement[];
  declare readonly hasAriaLiveTarget: boolean;
}
