import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['link', 'filterTemplate'];

  static values = {
    filterType: String,
    fieldClass: String,
  };

  connect() {
    if(this.filterTypeValue != 'radio') {
      const content = this.filterTemplateTarget.content.cloneNode(true);

      $(this.linkTarget).qtip({
        content: { text: content, title: false },
        position: { my: 'right top', at: 'bottom center' },
        show: {
          event: 'click', delay: 0
        },
        hide: {
          event: 'unfocus',
        },
        style: { classes: `sort-by-qtip list-filtering-qtip-${this.fieldClassValue}` },
      })
      .each(function() {
        // Prerender the tooltip content immediately so it loads faster when
        // clicking the links.
        setTimeout(() => {
          const api = $(this).qtip('api');
      
          // Programmatically render and hide immediately
          if (api) {
            api.render();
            api.hide(true); // Hides without animation
          }
        }, 250);
      });
    }
  }

  resetState() {
    $(this.element).find(".filter-field").each(function(index, field) {
      $(field).val($(field).data('initial-value'));
    });

    const subcomponents = ['forms--search--multi-select-filter-simple', 'forms--search--multi-select-filter-optimized'];
    
    if(this.filterTypeValue == 'radio') {
      var container = $(this.element);
    } else {
      var container_class = `.list-filtering-qtip-${this.fieldClassValue}`;
      var container = $(container_class);  
    }

    if(container.length > 0) {
      subcomponents.forEach(function(subcomponent) {
        container.find("[data-controller='" + subcomponent + "']").each(function(index, current) {
            var controller = Stimulus.getControllerForElementAndIdentifier($(current).get(0), subcomponent);
            if(!!controller) {
                controller.resetState();
            }
        }); 
      });
    }
  }
}
