import { Controller } from '@hotwired/stimulus';

export default class CommonCardDisclosureCard extends Controller {
  static values = { compact: Boolean };

  static classes = [
    'summaryRelaxed',
    'summaryCompact',
    'summaryChildren',
    'detailsRelaxed',
    'detailsCompact',
  ];

  static targets = ['summary', 'summaryChild'];

  compactValueChanged() {
    if (this.compactValue) {
      this.setCompact();
    } else {
      this.setRelaxed();
    }
  }

  setRelaxed() {
    this.element.classList.remove(...this.detailsCompactClasses);
    this.element.classList.add(...this.detailsRelaxedClasses);
    this.summaryTarget.classList.remove(...this.summaryCompactClasses);
    this.summaryTarget.classList.add(...this.summaryRelaxedClasses);
    this.summaryChildTargets.forEach((el) => el.classList.add(...this.summaryChildrenClasses));
  }

  setCompact() {
    this.element.classList.remove(...this.detailsRelaxedClasses);
    this.element.classList.add(...this.detailsCompactClasses);
    this.summaryTarget.classList.remove(...this.summaryRelaxedClasses);
    this.summaryTarget.classList.add(...this.summaryCompactClasses);
    this.summaryChildTargets.forEach((el) => el.classList.remove(...this.summaryChildrenClasses));
  }

  declare compactValue: boolean;
  declare readonly detailsRelaxedClasses: string;
  declare readonly detailsCompactClasses: string;
  declare readonly summaryRelaxedClasses: string;
  declare readonly summaryCompactClasses: string;
  declare readonly summaryChildrenClasses: string;
  declare readonly summaryTarget: HTMLElement;
  declare readonly summaryChildTargets: HTMLDivElement[];
}
