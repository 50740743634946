import { Controller } from '@hotwired/stimulus';
import { roundWithOptionalPrecision } from 'javascript/lib/utils';

export default class extends Controller {
  static targets = ['weight', 'scoringScaleRow', 'percentage', 'naColumn', 'weightColumn', 'percentColumn'];

  static outlets = ['scoring-fields--scoring-scales'];

  connect() {
    const self = this;
    setTimeout(() => {
      self.updatePercentageFromWeight(self.weightTarget.value);
      self.scoringFieldsScoringScalesOutlet.resetDeleteOptions();
    }, 1);
  }

  deleteScoringScale(e) {
    if (e.currentTarget.classList.contains('disabled') === false) {
      this.scoringScaleRowTarget.remove();
      this.scoringFieldsScoringScalesOutlet.handleScoringScaleRowChange();
      this.scoringFieldsScoringScalesOutlet.resetDeleteOptions();
    }
  }

  selectNullOptionCheckbox(e) {
    if (e.target.checked) {
      this.weightTarget.setAttribute('data-previous-weight', this.weightTarget.value);
      this.weightTarget.type = 'text';
      this.weightTarget.removeAttribute = 'pattern';
      this.weightTarget.value = 'N/A';
      this.weightTarget.setAttribute('data-null-option-weight', e.target.id);
      this.scoringFieldsScoringScalesOutlet.resetNullOptionCheckboxes(e);
      this.weightTarget.disabled = true;
    } else {
      this.weightTarget.type = 'number';
      this.weightTarget.setAttribute('pattern', '[0-9]{1,3}');
      if (this.weightTarget.dataset.previousWeight != null) {
        this.weightTarget.value = this.weightTarget.dataset.previousWeight;
      } else {
        this.weightTarget.value = 0;
      }

      this.weightTarget.setAttribute('data-null-option-weight', e.target.id);
      this.scoringFieldsScoringScalesOutlet.resetNullOptionCheckboxes(e);
      this.weightTarget.disabled = false;
    }
  }

  handleWeightChange() {
    this.scoringFieldsScoringScalesOutlet.resetPercentages();
  }

  updatePercentageFromWeight(value) {
    if (value === 0 || Number.isNaN(Number(value))) {
      this.percentageTarget.innerHTML = '0%';
      return;
    }
    const maxWeight = this.scoringFieldsScoringScalesOutlet.getMaxWeight();
    const newWeight = (value / maxWeight) * 100;
    if (newWeight === 0 || Number.isNaN(Number(newWeight))) {
      this.percentageTarget.innerHTML = '0%';
      return;
    }
    this.percentageTarget.innerHTML = `${roundWithOptionalPrecision(newWeight, 2)}%`;
  }

  updateNaColumnVisibility(visible) {
    if (visible) {
      this.naColumnTarget.classList.remove('hidden');
    } else {
      this.naColumnTarget.classList.add('hidden');
    }
  }

  updateWeightColumnVisibility(visible) {
    if (visible) {
      this.weightColumnTarget.classList.remove('hidden');
      this.percentColumnTarget.classList.remove('hidden');
    } else {
      this.weightColumnTarget.classList.add('hidden');
      this.percentColumnTarget.classList.add('hidden');
    }
  }
}
