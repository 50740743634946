import { Controller } from '@hotwired/stimulus';
import { useIntersection } from 'stimulus-use';
import NotificationBadgeController from './badge_controller';

export default class NotificationFrameController extends Controller {
  static targets = ['loading', 'currentNotifications', 'nextNotifications'];

  static outlets = ['notification--badge'];

  connect() {
    useIntersection(this);
  }

  appear() {
    // hide next notifications frame to prevent lazy loading additional pages
    this.nextNotificationsTarget.classList.add('hidden');

    // clear notifications list
    this.currentNotificationsTarget.innerHTML = '';

    // show loading screen
    this.loadingTarget.classList.remove('hidden');

    // clear notification badge count
    if (this.hasNotificationBadgeOutlet) {
      this.notificationBadgeOutlet.unreadIdsValue = [];
    }

    // fetch notifications
    NotificationFrameController.getNotifications().then(() => {
      // hide loading screen, show next notifications frame
      this.loadingTarget.classList.add('hidden');
      this.nextNotificationsTarget.classList.remove('hidden');
    });
  }

  static async getNotifications() {
    const response = await fetch('/notifications.turbo_stream?page=1');

    if (!response.ok) {
      throw new Error(`Error getting notifications: ${response.status}`);
    }

    const html = await response.text();

    window.Turbo.renderStreamMessage(html);
  }

  declare readonly loadingTarget: HTMLElement;
  declare readonly currentNotificationsTarget: HTMLElement;
  declare readonly nextNotificationsTarget: HTMLElement; // Turbo.FrameElement
  declare readonly hasNotificationBadgeOutlet: boolean;
  declare readonly notificationBadgeOutlet: NotificationBadgeController;
}
