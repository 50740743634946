import { Controller } from '@hotwired/stimulus';
import { useMeta } from 'stimulus-use';

const PENDING_REFRESH_TIMEOUT = 62000; // 62 seconds

export default class extends Controller {
  static metaNames = ['pusherApiKey', 'pusherCluster', 'pusherEncrypted'];

  static values = {
    liveInterviewId: String,
    calendarId: String,
    conferencePending: Boolean,
    conferencePendingStartedAt: String,
  }

  connect() {
    useMeta(this);

    if (this.pusherApiKeyMeta) {
      this.pusher = new window.Pusher(this.pusherApiKeyMeta, {
        cluster: this.pusherClusterMeta,
        encrypted: this.pusherEncryptedMeta,
      });

      const notificationsChannel = this.pusher.subscribe(`live_interview_item_${this.liveInterviewIdValue}`);

      // update when an event is pushed
      notificationsChannel.bind('update', () => {
        this.refreshLiveInterviewItem();
      });

      // Show message if error occurs
      notificationsChannel.bind('error', (data) => {
        window.showErrorMessage(window.i18n_js.conference_creation_error_short, data.message);
        this.refreshLiveInterviewItem();
      });
    }

    // If conference pending hasn't timed out, schedule a refresh when the timeout is reached
    const pendingStartedAt = new Date(this.conferencePendingStartedAtValue);
    const diff = Math.floor((new Date().getTime() - pendingStartedAt.getTime()));

    if (this.conferencePendingValue && diff < PENDING_REFRESH_TIMEOUT) {
      setTimeout(() => {
        this.refreshLiveInterviewItem();
      }, (PENDING_REFRESH_TIMEOUT - diff));
    }
  }

  disconnect() {
    // Unsubscribe from the pusher channel
    let notificationsChannel = this.pusher.channel(`live_interview_item_${this.liveInterviewIdValue}`);
    notificationsChannel?.unbind('update');
    notificationsChannel?.unbind('error');
    this.pusher.unsubscribe(`live_interview_item_${this.liveInterviewIdValue}`);
    this.pusher.disconnect();
  }

  refreshLiveInterviewItem() {
    window.Turbo.visit(window.location.href, { frame: `live-interview-item-${this.liveInterviewIdValue}` });
  }

  reloadConferenceURL() {
    const url = `/live-interviews/check-conference-connection/${this.liveInterviewIdValue}`;

    $.ajax({
      url,
      success: () => {
        // Reload the interview list
        this.refreshLiveInterviewItem();
      },
      error: (response) => {
        if (response.status == 404) {
          $.ajax({
            url:   '/live-interview/retry-conference-creation',
            async: true,
            data:  { live_interview_id: this.liveInterviewIdValue },
            error: () => {
              window.showErrorMessage(window.i18n_js.an_error_occured);
            }
          });
        } else if (response.status == 500) {
          // The live interview was scheduled in the past and we cannot create a conference for it
          window.showErrorMessage(window.i18n_js.conference_creation_error_short, window.i18n_js.conference_creation_error_past);
        } else {
          this.refreshLiveInterviewItem();
        }
      }
    });
  }

  declare readonly pusherApiKeyMeta: string | undefined;
  declare readonly pusherClusterMeta: string | undefined;
  declare readonly pusherEncryptedMeta: string | undefined;
  declare liveInterviewIdValue: string;
  declare calendarIdValue: string;
  declare conferencePendingValue: boolean;
  declare conferencePendingStartedAtValue: string;
  declare pusher: any | null;
}
