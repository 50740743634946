import { Controller } from '@hotwired/stimulus';
import { interpolateTemplate } from 'javascript/lib/utils';

export default class extends Controller {
  static targets = ['scoringQuestionRows', 'scoringQuestionRowTemplate'];

  static outlets = ['scoring-fields--question-row'];

  static values = {
    placeholders: { type: Array, default: [] },
  };

  // eslint-disable-next-line class-methods-use-this
  resetDeleteOptions() {
    const deleteButtons = document.getElementsByClassName('scoring-fields--question-row-delete-button');
    if (deleteButtons.length !== 0) {
      if (deleteButtons.length === 1) {
        deleteButtons[0].classList.add('disabled');
      } else {
        deleteButtons[0].classList.remove('disabled');
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  scoringQuestionRowsTargetConnected() {
    $('[data-scoring-fields--questions-target=\'scoringQuestionRows\']').sortable({
      handle: '.reorder-handle',
      axis: 'y',
      helper(e, tr) {
        const $originals = tr.children();
        const $helper = tr.clone();
        $helper.children().each((index) => {
          $(this).width($originals.eq(index).width());
        });

        return $helper;
      },
    });

    // Add the required validation for the to fields.
    jQuery.validator.addClassRules('scoring-field-option-value-field', {
      trim: true,
      uniqueByClass: ['scoring-field-option-value-field'],
    });
  }

  addQuestion() {
    const newIndex = this.scoringQuestionRowsTarget.children.length;
    let placeholder = '';
    if (this.placeholdersValue[newIndex] !== undefined) {
      placeholder = this.placeholdersValue[newIndex];
    }
    const data = { index: newIndex, placeholder };
    const clone = this.scoringQuestionRowTemplateTarget.content.firstElementChild.cloneNode(true);
    const node = interpolateTemplate(clone.outerHTML, data);
    this.scoringQuestionRowsTarget.insertAdjacentHTML('beforeend', node);
    this.resetDeleteOptions();
  }
}
