import { Controller } from '@hotwired/stimulus';
import { useClickOutside, useDebounce } from 'stimulus-use';
import { useHotkeys } from 'stimulus-use/dist/hotkeys';

type TemplateFields = {
  id: string,
  element: HTMLElement | HTMLAnchorElement | null
}

export default class NavbarSearchController extends Controller {
  static targets = ['input', 'results', 'positionTemplate', 'applicantTemplate', 'headingTemplate', 'showMoreTemplate', 'searchIcon', 'loadingIcon'];

  static values = { url: String };

  static debounces = ['search'];

  formSubmissionInProgress: boolean = false;

  connect() {
    useDebounce(this, { wait: 500 });
    useClickOutside(this, { element: this.element });
    useHotkeys(this, {
      esc: [this.clearResults],
    });
  }

  throttleFormSubmission(event) {
    if (this.formSubmissionInProgress) {
      event.preventDefault();
      return false;
    }

    this.formSubmissionInProgress = true;
    return true;
  }

  clickOutside() {
    this.clearResults();
  }

  async search() {
    // only fetch if input has 3 or more characters
    if (this.inputTarget.value.length >= 3) {
      this.showLoadingIcon();

      const results = await this.getResults();

      // clear results before building new results
      this.clearResults();

      results.forEach((result) => {
        switch (result.t) {
          // Heading
          case 't':
            this.renderHeading(result);
            break;
          // Applicant
          case 'a':
            this.renderApplicant(result);
            break;
          // Business Process / Position
          case 'b':
            this.renderPosition(result);
            break;
          // Reference
          case 'r':
            this.renderApplicant(result);
            break;
          // Show more
          case 'm':
            this.renderShowMore(result);
            break;
          // Default
          default:
            break;
        }
      });

      this.hideLoadingIcon();
      this.showResults();
    }
  }

  static generateHref(item) {
    switch (item.t) {
      case 'b':
        return `/processes/${item.id}/edit`;
      case 'a':
        return `/processes/${item.bp_id}/person/${item.id}?from_search=1`;
      case 'r':
        return `/processes/${item.bp_id}/person/${item.id}?active_person_tab=references`;
      default:
        return '#';
    }
  }

  renderHeading(result) {
    // clone the template
    const clone = this.headingTemplateTarget.content.cloneNode(true) as HTMLElement;

    // update the content
    const label = clone.querySelector('.navigation--navbar--search-label');
    if (label) {
      label.textContent = result.label;
    }

    // append the element to the results
    this.resultsTarget.appendChild(clone);
  }

  renderShowMore(result) {
    // clone the template
    const clone = this.showMoreTemplateTarget.content.cloneNode(true) as HTMLElement;

    // update the content
    const anchor: HTMLAnchorElement | null = clone.querySelector('.navigation--navbar--search-anchor');
    if (anchor && this.inputTarget.value) {
      anchor.textContent = result.label;
      anchor.href = `/global-search?auto_search=1&term=${this.inputTarget.value}#results`;
    }

    // append the element to the results
    this.resultsTarget.appendChild(clone);
  }

  renderApplicant(result) {
    // clone the template
    const node = this.applicantTemplateTarget.content.cloneNode(true) as HTMLElement;

    // select the template fields that require content
    const fields: Array<TemplateFields> = [
      { id: 'label', element: node.querySelector('.navigation--navbar--search-label') },
      { id: 'email', element: node.querySelector('.navigation--navbar--search-email') },
      { id: 'bp', element: node.querySelector('.navigation--navbar--search-bp') },
      { id: 'ref', element: node.querySelector('.navigation--navbar--search-ref') },
      { id: 'avatar', element: node.querySelector('.navigation--navbar--search-avatar') },
      { id: 'id', element: node.querySelector('.navigation--navbar--search-anchor') },
    ];

    // update field content
    fields.forEach((el) => {
      const content = result[el.id];

      // make sure there is an element
      if (el.element) {
        // avatar
        if (el.id === 'avatar') {
          el.element?.insertAdjacentHTML('beforeend', content);

        // id
        } else if (el.id === 'id') {
          const cloneElElement = el.element as HTMLAnchorElement;
          cloneElElement.href = NavbarSearchController.generateHref(result);
          // eslint-disable-next-line no-param-reassign
          el.element = cloneElElement;

        // everything else
        } else {
          // eslint-disable-next-line no-param-reassign
          el.element.textContent = this.decodeHtmlEntities(content);
        }
      }
    });

    // append the hydrated node to the results
    this.resultsTarget.appendChild(node);
  }

  // Ensure things like &amp; are converted to &
  decodeHtmlEntities(text) {
    // Creating Textareas can be used to decode HTML entities
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.textContent || textArea.innerText;
  }

  renderPosition(result) {
    // clone the template
    const node = this.positionTemplateTarget.content.cloneNode(true) as HTMLElement;

    // select the template fields that require content
    const fields: Array<TemplateFields> = [
      { id: 'label', element: node.querySelector('.navigation--navbar--search-label') },
      { id: 'ref', element: node.querySelector('.navigation--navbar--search-ref') },
      { id: 'status', element: node.querySelector('.navigation--navbar--search-status') },
      { id: 'id', element: node.querySelector('.navigation--navbar--search-anchor') },
    ];

    // update field content
    fields.forEach((el) => {
      const content = result[el.id];

      // make sure there is an element
      if (el.element) {
        // status
        if (el.id === 'status') {
          el.element.insertAdjacentHTML('beforeend', content);

        // id
        } else if (el.id === 'id') {
          const cloneElElement = el.element as HTMLAnchorElement;
          cloneElElement.href = NavbarSearchController.generateHref(result);
          // eslint-disable-next-line no-param-reassign
          el.element = cloneElElement;

        // everything else
        } else {
          // eslint-disable-next-line no-param-reassign
          el.element.innerHTML = this.decodeHtmlEntities(content);
        }
      }
    });

    // append the hydrated node to the results
    this.resultsTarget.appendChild(node);
  }

  showLoadingIcon() {
    this.searchIconTarget.classList.add('hidden');
    this.loadingIconTarget.classList.remove('hidden');
  }

  hideLoadingIcon() {
    this.loadingIconTarget.classList.add('hidden');
    this.searchIconTarget.classList.remove('hidden');
  }

  showResults() {
    this.resultsTarget.classList.remove('hidden');
  }

  clearResults() {
    this.resultsTarget.classList.add('hidden');
    this.resultsTarget.innerHTML = '';
  }

  async getResults() {
    const params   = new URLSearchParams({ term: this.inputTarget.value });
    const response = await fetch(`${this.urlValue}?` + params, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.json();
  }

  declare readonly inputTarget: HTMLInputElement;
  declare readonly resultsTarget: HTMLUListElement;
  declare readonly positionTemplateTarget: HTMLTemplateElement;
  declare readonly applicantTemplateTarget: HTMLTemplateElement;
  declare readonly headingTemplateTarget: HTMLTemplateElement;
  declare readonly showMoreTemplateTarget: HTMLTemplateElement;
  declare readonly searchIconTarget: HTMLElement;
  declare readonly loadingIconTarget: HTMLElement;
  declare urlValue: string;
}
