import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { id: String, isCurrent: Boolean, completeText: String, incompleteText: String, isComplete: Boolean, currentClass: String, completeClass: String, incompleteClass: String };

  static outlets = ['common--progress--steps'];
  
  static targets = ['completedText', 'title', 'description'];

  isCurrentValueChanged() {
    this.refreshState();
  }

  isCompleteValueChanged() {
    this.refreshState();
  }

  refreshState() {
    this.element.classList.remove(this.currentClassValue);
    this.element.classList.remove(this.completeClassValue);
    this.element.classList.remove(this.incompleteClassValue);

    $(this.element).removeAttr('aria-current');

    if(this.isCompleteValue) {
      $(this.completedTextTarget).html(this.completeTextValue);
    } else {
      $(this.completedTextTarget).html(this.incompleteTextValue);
    }

    if(this.isCurrentValue) {
      this.element.classList.add(this.currentClassValue);
      $(this.element).attr('aria-current', true);
    } else if(this.isCompleteValue) {
      this.element.classList.add(this.completeClassValue);
    } else {
      this.element.classList.add(this.incompleteClassValue);
    }
  }
}