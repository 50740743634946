import { Controller } from '@hotwired/stimulus';
import type { Components } from '@duetds/date-picker/dist/types/components';
import { isMobileOrTablet } from '../../../javascript/lib/utils';

export default class extends Controller {
  static targets = ['tooltip', 'datePicker', 'store', 'nativeDatePicker', 'clearDate'];

  static values = {
    class: String, localization: String, name: String, id: String,
  };

  /* Lifecycle */
  connect() {
    if (this.hasTooltipTarget){
      $(this.tooltipTarget).qtip();
      this.tooltipTarget.addEventListener('focus', ()=>{
        $(this.tooltipTarget).qtip('show');
      });
      this.tooltipTarget.addEventListener('blur', ()=>{
        $(this.tooltipTarget).qtip('hide');
      });
    }
    if (isMobileOrTablet()) {
      // @ts-ignore
      if (this.hasDatePickerTarget) this.datePickerTarget.remove();
      if (this.hasClearDateTarget) this.clearDateTarget.remove();
      if (this.hasTooltipTarget) this.tooltipTarget.remove();
    } else {
      if (this.hasNativeDatePickerTarget) this.nativeDatePickerTarget.remove();
      if (this.hasDatePickerTarget) {
        this.localize();
        this.customizeInterfaceInputOnHydration();
      }
    }
  }

  /* Actions */
  update(e) {
    // Update the value of the hidden store field.
    if (this?.hasStoreTarget) {
      this.storeTarget.value = e.target.value;

      if ($(this.storeTarget).closest('.vidcruiter-typing-test-container').length === 0) {
        // @ts-ignore
        // eslint-disable-next-line
        saveTempFieldAnswer($(this.storeTarget));
      }
    }
  }

  clearDate() {
    // Clear date picker and hidden store field values
    if (!isMobileOrTablet()) {
      if (this.hasDatePickerTarget) this.datePickerTarget.value = '';
    }
    if (this.hasStoreTarget) this.storeTarget.value = '';
  }

  localize() {
    const localization = JSON.parse(this.localizationValue);
    this.datePickerTarget.localization = localization;
  }

  setDateFormat() {
    const locale = document.documentElement.lang || /* use host locale */ undefined;
    const options: any = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    };

    // @ts-ignore
    this.datePickerTarget.dateAdapter = {
      format: (date) => date.toLocaleString(locale, options),
    };
  }

  customizeInterfaceInputOnHydration() {
    const self = this;
    $.onmount(`#${this.idValue}`, function () {
      this.name = self.idValue;
      if(self.classValue){
        if(!this.classList.contains(self.classValue)){
          this.classList.add(self.classValue);
          $.onmount();
        }
      }
      this.setAttribute('readonly', 'true');
      this.addEventListener('click', () => {
        self.datePickerTarget.show();
      });
      this.addEventListener('keydown', (e) => {
        if (['Enter', 'Space'].includes(e.code)) {
          e.preventDefault();
          self.datePickerTarget.show();
        }
      });
      const toggle = this.parentElement.querySelector(`.duet-date__toggle`);
      if(toggle){
        toggle.addEventListener('keydown', (e) => {
          if (['Enter', 'Space'].includes(e.code)) {
            e.preventDefault();
            self.datePickerTarget.show();
          }
        });
      }
    });
  }


  declare readonly idValue: string;
  declare readonly nameValue: string;
  declare readonly classValue: string;
  declare readonly isRequiredValue: boolean;
  declare readonly hasNameValue: boolean;
  declare readonly hasClassValue: boolean;
  declare readonly localizationValue: string;
  declare readonly storeTarget: HTMLInputElement;
  declare readonly tooltipTarget: HTMLButtonElement;
  declare readonly clearDateTarget: HTMLButtonElement;
  declare readonly nativeDatePickerTarget: HTMLInputElement;
  declare readonly datePickerTarget: Components.DuetDatePicker;
  declare readonly hasDatePickerTarget: boolean;
  declare readonly hasStoreTarget: boolean;
  declare readonly hasClearDateTarget: boolean;
  declare readonly hasTooltipTarget: boolean;
  declare readonly hasNativeDatePickerTarget: boolean;
}
