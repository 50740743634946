import { Controller } from '@hotwired/stimulus';
import { addURLParam, removeURLParam } from '../../../javascript/lib/utils';

export default class extends Controller {
  static targets = ['sidebar', 'content', 'transitionContainer'];
  static values = {
    id: String,
    bodyScrollLock: String,
    open: String,
    close: String,
    openWithUrlParam: String,
    urlParam: String,
    turboFrameId: String,
    loadTurboFrameOnOpen: String,
    loadTurboFrameWithUrlParam: String,
    turboFrameSrcParamName: String,
  };

  originalState: null;
  sidebarState: object;

  constructor(props){
    super(props); 
    this.originalState = null;
    this.sidebarState = {sidebar: 'true'};
    this.handlePopstateEvent = this.handlePopstateEvent.bind(this);
  }

  connect() {
    // Add open event listeners
    if (this.openValue !== 'false') {
      const triggers = document.querySelectorAll(`.${this.openValue}`);
      triggers.forEach((trigger) => {
        trigger.addEventListener('click', this.openSidebar.bind(this));
      });
    }
    // Add close event listeners
    if (this.closeValue !== 'false') {
      const triggers = document.querySelectorAll(`.${this.closeValue}`);
      triggers.forEach((trigger) => {
        trigger.addEventListener('click', this.closeSidebar.bind(this));
      });
    }

    // Open the sidebar if the parameter is set in the url and is enabled.
    if (this.openWithUrlParamValue === 'true') {
      const urlParams = new URLSearchParams(window.location.search);
      // eslint-disable-next-line max-len
      if (urlParams.has(this.urlParamValue as string) && urlParams.get(this.urlParamValue as string) === this.idValue) {
        this.openSidebar();
      }
    }

    // Set and reload a turbo frame on page load.
    if (this.loadTurboFrameWithUrlParamValue === 'true') {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has(this.turboFrameSrcParamNameValue as string)) {
        const src = urlParams.get(this.turboFrameSrcParamNameValue as string);
        if (src) {
          const frame = document.getElementById(this.turboFrameIdValue as string);
          if (frame) {
            if (frame.getAttribute('src') !== src) {
              frame.setAttribute('src', src);
              // @ts-ignore
              frame.reload();
            }
          }
        }
      }
    }
  }

  disconnect() {
    window.removeEventListener('popstate', this.handlePopstateEvent);
  }

  openSidebar(e?: any) {
    // If a Turbo Frame is to be loaded on open, get src from the trigger
    // element [data-turbo-frame-src] attribute, and add it to the frame and reload it.
    if (this.loadTurboFrameOnOpenValue === 'true' && this.turboFrameIdValue !== '') {
      if (e?.target) {
        const src = e?.target?.getAttribute('data-turbo-frame-src');
        if (src) {
          const frame = document.getElementById(this.turboFrameIdValue as string);
          if (frame) {
            if (frame.getAttribute('src') !== src) {
              frame.setAttribute('src', src);
              // @ts-ignore
              frame.reload();
              if (this.loadTurboFrameWithUrlParamValue === 'true') {
                addURLParam(this.turboFrameSrcParamNameValue, src);
              }
            }
          }
        }
      }
    }
    if (this.bodyScrollLockValue === 'true') {
      document.body.style.overflow = 'hidden';
      if(document.body.scrollHeight > document.body.clientHeight){ // Only compensate if scroll bar exist.
        document.body.style.width = 'calc(100vw - 15px)'; // Compensate width reflow. (Scroll bar width)
      }
    }
    
    // Hide the back-to-top button
    document.getElementById('back-top')?.classList.add('!hidden');

    addURLParam(this.urlParamValue, this.idValue);
    this.sidebarTarget.classList.remove('hidden');
    this.sidebarTarget.classList.add('sidebar-open');
    setTimeout(() => {
      this.sidebarTarget.classList.remove('opacity-0');
      this.sidebarTarget.classList.add('opacity-100');
      this.contentTarget.classList.remove('translate-x-full');
      this.contentTarget.classList.add('translate-x-0');
    }, 1); // Wont animate if you add the classes at the same time as hidden.'
    this.originalState = history.state;
    window.addEventListener('popstate', this.handlePopstateEvent);
    history.pushState(this.sidebarState, '', location.href);
  }

  closeSidebar() {
    removeURLParam(this.urlParamValue);
    if (this.bodyScrollLockValue === 'true') {
      // @ts-ignore
      document.body.style.overflow = null;
      // @ts-ignore
      document.body.style.width = null;
    }

     // Show the back-to-top button
     document.getElementById('back-top')?.classList.remove('!hidden');

    if (this.loadTurboFrameWithUrlParamValue === 'true') {
      removeURLParam(this.turboFrameSrcParamNameValue);
    }
    this.contentTarget.classList.add('translate-x-full');
    this.contentTarget.classList.remove('translate-x-0');
    this.sidebarTarget.classList.add('opacity-0');
    this.sidebarTarget.classList.remove('opacity-100');
    this.sidebarTarget.classList.remove('sidebar-open');
    setTimeout(() => {
      if (!this.sidebarTarget.classList.contains('sidebar-open')) {
        this.sidebarTarget.classList.add('hidden');
      }
    }, 200); // Wait for animations to finish
    window.removeEventListener('popstate', this.handlePopstateEvent);
    while(history.state === this.sidebarState){
      history.go(-1);
    }
    
  }

  handlePopstateEvent(e) {
    this.closeSidebar();
  }

  declare readonly transitionContainerTarget: HTMLElement;
  declare readonly sidebarTarget: HTMLElement;
  declare readonly contentTarget: HTMLElement;
  declare readonly idValue: String;
  declare readonly bodyScrollLockValue: String; 
  declare readonly openValue: String;
  declare readonly closeValue: String;
  declare readonly urlParamValue: String;
  declare readonly openWithUrlParamValue: String;
  declare readonly turboFrameIdValue: String;
  declare readonly loadTurboFrameOnOpenValue: String;
  declare readonly loadTurboFrameWithUrlParamValue: String;
  declare readonly turboFrameSrcParamNameValue: String;
}