import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['fieldClass'];

  static values = {
    inlineResults: Boolean,
  };

  connect() {}

  // There is a problem with the click event when the dropdown is inline display, is open and the user clicks on the button
  // because the blur causes the results disappears before the click event is handled making the button click to not be registered.
  // This method is a workaround to handle this case as the mousedown event happens before the blur event.
  updateFilterMousedown() {
    if($(document.activeElement).closest('.ts--inline-results').length > 0 || $(document.activeElement).hasClass('filtering-option')) {
      this.updateFilter();
    };
  }

  removeFilterMousedown() {        
    if($(document.activeElement).closest('.ts--inline-results').length > 0 || $(document.activeElement).hasClass('filtering-option')) {
      this.removeFilter();
    };
  }

  hidePopup() {
    $(this.element).closest('.qtip').qtip('hide');
  }

  updateFilter() {
    this.hidePopup();

    window.updateFilterBy($(this.element));
    window.sortByUpdate($('.list-filtering'));
  }

  removeFilter() {
    this.hidePopup();

    const filteringContainer = $('.list-filtering');
    const filterByFieldClass = $(this.fieldClassTarget).val();
    const filterByInput = $(filteringContainer.find(`.${filterByFieldClass}`));

    const filterByInputInclusive = $(filteringContainer.find(`.${filterByFieldClass}-inclusive`));

    filterByInputInclusive.val('true');
    filterByInput.val('none');

    window.sortByUpdate($('.list-filtering'));
  }

  resetState() {
    AdvancedSelect.helper.setValue($(this.element).find(".filter-by-select"), $(this.element).data("initial-filtered-value"));

    $(this.element).find(`[js-inclusive-${$(this.element).data('initial-inclusive-value')}]`).prop('checked', true);
  }
}
