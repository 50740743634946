import { Controller } from '@hotwired/stimulus';
import { useHover } from 'stimulus-use';

export default class extends Controller {
  static targets = ['transcriptionSegments', 'transcriptionSegmentRow', 'hoverable'];
  static outlets = ['transcription--transcription-speaker-attribution-selector'];
  static values = {
    currentuserid: { type: Number, default: 0 },
  };

  connect() {
    this.transcriptionSegmentRowTargets.forEach((el) => {
      useHover(this, { element: el });
    });
    this.hoverableTargets.forEach((el) => {
      useHover(this, { element: el });
    });
    // Ensure the parent openDialogV3 container is centered
    centerOpenDialogs();
  }

  mouseEnter(e) {
    if (e.target.classList.contains('segmentRow') && e.target.querySelector('.changeSpeaker')) {
      e.target.querySelector('.changeSpeaker').classList.remove('hidden');
    }
  }

  mouseLeave(e) {
    if (e.target.classList.contains('segmentRow') && e.target.querySelector('.changeSpeaker')) {
      e.target.querySelector('.changeSpeaker').classList.add('hidden');
    }
    if (e.target.classList.contains('speakerPicker')) {
      if (document.querySelector('[data-segmentspeakerselectorid].visible') != null) {
        document.querySelector('[data-segmentspeakerselectorid].visible').classList.add('hidden');
        document.querySelector('[data-segmentspeakerselectorid].visible').classList.remove('visible');
      }
    }
  }
  // we have been given a text string to serve as the new label for a speaker slot
  updateSpeakerWithText(speakerId, speakerLabel) {
    $('.prettyLoader').fadeIn();
    fetch(`/transcription/transcription_speakers/${speakerId}.turbo_stream?label=${speakerLabel}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
    }).then((response) => response.text())
      .then((html) => {
        $('.prettyLoader').stop().hide();
          window.Turbo.renderStreamMessage(html);
        }
      );
  }

  // we have been given a LiveInterviewArchiveJoinableHistory.id serve as the new speaker
  updateSpeakerWithInterview(speakerId, liveInterviewArchiveJoinableHistoryId) {
    $('.prettyLoader').fadeIn();
    fetch(`/transcription/transcription_speakers/${speakerId}.turbo_stream?live_interview_archive_joinable_history_id=${liveInterviewArchiveJoinableHistoryId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
    }).then((response) => response.text())
      .then((html) => {
        $('.prettyLoader').stop().hide();
        window.Turbo.renderStreamMessage(html);
      }
    );

  }

  // we have been given the id of an existing speaker to subtitute as the new speaker
  updateSpeakerWithSpeaker(speakerId, newSpeakerId) {
    $('.prettyLoader').fadeIn();
    fetch(`/transcription/transcription_speakers/${speakerId}.turbo_stream?speaker_id=${newSpeakerId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
    }).then((response) => response.text())
      .then((html) => {
        $('.prettyLoader').stop().hide();
        window.Turbo.renderStreamMessage(html);
      }
    );
  }

  // open the segment edit ux
  startSegmentEditing(e) {
      this.cancelSegmentEditing();

      document.querySelector('[data-viewsegmentid="'+e.target.dataset.segmentid+'"]').classList.remove('visible');
      document.querySelector('[data-viewsegmentid="'+e.target.dataset.segmentid+'"]').classList.add('hidden');

      document.querySelector('[data-editorsegmentid="'+e.target.dataset.segmentid+'"]').classList.remove('hidden');
      document.querySelector('[data-editorsegmentid="'+e.target.dataset.segmentid+'"]').classList.add('visible');

      const textarea = document.querySelector('[data-textareasegmentid="'+e.target.dataset.segmentid+'"]');
      textarea.style.height = "1px";
      textarea.style.height = (10+textarea.scrollHeight)+"px";
  }

  // close the segment editing ux
  cancelSegmentEditing() {
    const visibleSegmentEditors = document.querySelectorAll('[data-editorsegmentid]');
    visibleSegmentEditors.forEach((segmentEditor) => {
      segmentEditor.classList.remove('visible');
      segmentEditor.classList.add('hidden');
    });

    const hiddenSegments = document.querySelectorAll('[data-viewsegmentid]');
      hiddenSegments.forEach((segment) => {
        segment.classList.remove('hidden');
        segment.classList.add('visible');
      });
  }

  // perform an update of a segment
  updateSegment(e) {
    const textarea = document.querySelector('[data-textareasegmentid="'+e.target.dataset.inputsegmentid+'"]');

    let speakerAttributionWidgetVisible =  (document.querySelector('[data-speakerAttributionWidget]') && document.querySelector('[data-speakerAttributionWidget]').offsetParent !== null);

    $('.prettyLoader').fadeIn();
    fetch(`/transcription/transcription_segments/${e.target.dataset.inputsegmentid}.turbo_stream`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      body: JSON.stringify({ content: textarea.value, speaker_attribution_widget_visible: speakerAttributionWidgetVisible}),

    }).then((response) => response.text())
      .then((html) =>{
        $('.prettyLoader').stop().hide();
        window.Turbo.renderStreamMessage(html);
      }
    );
  }

  changeSpeaker(e) {
    this.cancelSegmentEditing();
    if (document.querySelector('[data-segmentspeakerselectorid="'+e.target.dataset.segmentid+'"]') != null) {
      document.querySelector('[data-segmentspeakerselectorid="'+e.target.dataset.segmentid+'"]').classList.remove('hidden');
      document.querySelector('[data-segmentspeakerselectorid="'+e.target.dataset.segmentid+'"]').classList.add('visible');
    }
  }

  // change the speaker for a segment to another
  changeSegmentSpeaker(e) {
    $('.prettyLoader').fadeIn();

    let speakerAttributionWidgetVisible =  (document.querySelector('[data-speakerAttributionWidget]') && document.querySelector('[data-speakerAttributionWidget]').offsetParent !== null);

    fetch(`/transcription/transcription_segments/${e.target.dataset.segmentid}.turbo_stream`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      body: JSON.stringify({ speaker_id: e.target.dataset.newspeaker, speaker_attribution_widget_visible: speakerAttributionWidgetVisible }),

    }).then((response) => response.text())
      .then((html) => {
        $('.prettyLoader').stop().hide();
        window.Turbo.renderStreamMessage(html);
      }
    );
  }

  // called from app/frontend/components/transcription/transcription_speaker_attribution_controller.js
  // to create a new speaker slot with a text label that segments can be associated with
  createSpeakerWithText(transcriptionRequestId, speakerLabel) {
    $('.prettyLoader').fadeIn();
    // we have been given a text string to serve as the new label for a speaker
    fetch(`/transcription/transcription_speakers.turbo_stream`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      body: JSON.stringify({transcription_request_id: transcriptionRequestId, label: speakerLabel }),
    }).then((response) => response.text())
      .then((html) => {
        $('.prettyLoader').stop().hide();
        window.Turbo.renderStreamMessage(html);
      }
    );
  }

  // called from app/frontend/components/transcription/transcription_speaker_attribution_controller.js
  // to create a new speaker slot with a LiveInterviewArchiveJoinableHistory.id that segments can be associated with
  createSpeakerWithInterview(transcriptionRequestId, liveInterviewArchiveJoinableHistoryId) {
    $('.prettyLoader').fadeIn();
    // we have been given a live_interview_archive_joinable_history_id to serve as the new speaker
    fetch(`/transcription/transcription_speakers.turbo_stream`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      body: JSON.stringify({transcription_request_id: transcriptionRequestId, live_interview_archive_joinable_history_id: liveInterviewArchiveJoinableHistoryId }),
    }).then((response) => response.text())
      .then((html) => {
        $('.prettyLoader').stop().hide();
        window.Turbo.renderStreamMessage(html);
      }
    );
  }
}
