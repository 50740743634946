import { Controller } from '@hotwired/stimulus';

export default class StatsChartController extends Controller {
  static targets = ['graph', 'spinner', 'button'];

  static values = {
    id: String,
    startDate: String,
    chartSeriesData: Array,
    name: String,
    tooltipDayLabel: String,
    labelFormat: String,
    langShortMonths: String,
    langWeekdays: String,
  };

  chart;

  initialize() {
    const { labelFormatValue } = this;
    const startDate = window.moment(this.startDateValue);

    // use the first button as the initial chart series data values
    const initialChartData = JSON.parse(this.buttonTargets[0].dataset.chartSeriesData as string);

    window.Highcharts.setOptions({
      lang: {
        shortMonths: this.langShortMonthsValue.split(','),
        weekdays: this.langWeekdaysValue.split(','),
      },
    });

    this.chart = new window.Highcharts.Chart({
      credits: {
        enabled: false,
      },
      chart: {
        renderTo: `${this.idValue}_graph`,
        animation: false,
        zoomType: 'none',
        height: 340,
        spacingTop: 10,
        spacingRight: 10,
        spacingBottom: 10,
        spacingLeft: 10,
        type: 'spline',
      },
      title: { text: null },
      xAxis: {
        type: 'datetime',
        gridLineWidth: 1,
        gridLineColor: '#EDEDED',
        lineWidth: 2,
        lineColor: '#AAAAAA',
        labels: {
          formatter() {
            return window.Highcharts.dateFormat(labelFormatValue, this.value);
          },
        },
      },
      yAxis: {
        title: null,
        minorGridLineWidth: 1,
        gridLineWidth: 1,
        gridLineColor: '#D8D8D8',
        alternateGridColor: '#F0F0F0',
        lineWidth: 3,
        lineColor: '#AAAAAA',
        endOnTick: false,
        startOnTick: false,
        maxPadding: 0.05,
        minPadding: 0.05,
        // min: this.chartSeriesDataValue <= 0 ? 0 : null,
        min: null,
        allowDecimals: false,
      },
      tooltip: {
        shared: true,
        crosshairs: true,
        shadow: false,
        dateTimeLabelFormats: {
          day: this.tooltipDayLabelValue,
        },
      },
      legend: false,
      plotOptions: {
        spline: {
          lineWidth: 4,
          states: {
            hover: {
              lineWidth: 5,
            },
          },
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: true,
                symbol: 'circle',
                radius: 5,
                lineWidth: 1,
              },
            },
          },
          pointInterval: 24 * 3600 * 1000, // one day
          pointStart: Date.UTC(...startDate.toArray()),
        },
      },
      series: [
        {
          name: this.nameValue,
          data: initialChartData,
        },
      ],
    });
  }

  async updateChartData(id: string, chartSeriesData?: number[]) {
    let chartData: number[] = [];

    // use local chart data if it exists
    if (chartSeriesData) {
      chartData = chartSeriesData;
    } else {
      // otherwise fetch new chart data
      this.showSpinner();
      chartData = await StatsChartController.fetchChartData(id);
    }

    // add data to chart
    this.chart.series[0].setData(chartData);

    // update y-axis
    if (chartData.reduce((acc, cur) => acc + cur, 0) <= 0) {
      this.chart.yAxis[0].setExtremes(0, null);
    } else {
      this.chart.yAxis[0].setExtremes(null, null);
    }

    // show chart
    return this.showChart();
  }

  setActive(e: { target: HTMLButtonElement; }) {
    const { resourceId, chartSeriesData } = e.target.dataset;

    this.removeAllActiveClasses();
    StatsChartController.addActiveClasses(e.target);

    if (resourceId) {
      this.updateChartData(resourceId, chartSeriesData && JSON.parse(chartSeriesData));
    }
  }

  static addActiveClasses(element: HTMLButtonElement) {
    element.classList.add('border-primary');
    element.classList.remove('border-neutral-200');
  }

  static removeActiveClasses(element: HTMLButtonElement) {
    element.classList.add('border-neutral-200');
    element.classList.remove('border-primary');
  }

  removeAllActiveClasses() {
    this.buttonTargets.forEach((element) => {
      StatsChartController.removeActiveClasses(element);
    });
  }

  showChart() {
    this.spinnerTarget.classList.add('hidden');
    this.graphTarget.classList.remove('hidden');
  }

  showSpinner() {
    this.graphTarget.classList.add('hidden');
    this.spinnerTarget.classList.remove('hidden');
  }

  static fetchChartData(id: string) {
    return fetch(`/companies/stats.json?selected_id=${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      });
  }

  declare readonly graphTarget: HTMLElement;
  declare readonly spinnerTarget: HTMLElement;
  declare readonly buttonTargets: HTMLButtonElement[];
  declare idValue: string;
  declare langShortMonthsValue: string;
  declare langWeekdaysValue: string;
  declare labelFormatValue: string;
  declare tooltipDayLabelValue: string;
  declare startDateValue: string;
  declare nameValue: string;
}
