import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    state: String,
  };

  static targets = ['icon'];

  connect() {
    if (this.stateValue === 'up') {
      this.expand();
    } else {
      this.collapse();
    }
  }

  clicked() {
    if (this.stateValue === 'down') {
      this.expand();
    } else if (this.stateValue === 'up') {
      this.collapse();
    }
  }

  expand() {
    this.stateValue = 'up';
    this.iconTarget.classList.remove('fa-chevron-down');
    this.iconTarget.classList.add('fa-chevron-up');
  }

  collapse() {
    this.stateValue = 'down';
    this.iconTarget.classList.remove('fa-chevron-up');
    this.iconTarget.classList.add('fa-chevron-down');
  }
}
