import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['image'];
  static values = { imageString: String, selectedImageIndex: Number };
  
  connect(){
    if(this.hasImageStringValue && this.hasImageTarget && this.imageStringValue !== ''){
      const images = this.imageStringValue.split(','); 
      this.imageTarget.style.backgroundImage = `url(${images[0]})`;
    }
  }

  slide(event){
    if(this.hasImageStringValue && this.hasImageTarget){
      const images = this.imageStringValue.split(','); 
      const len = images.length;
      let index = 0;
      if(event?.params?.direction === 'right'){
        if(this.selectedImageIndexValue >= (len - 1)){
          index = 0;
        }else{
          index = this.selectedImageIndexValue + 1;
        }
      }
      if(event?.params?.direction === 'left'){
        if(this.selectedImageIndexValue === 0){
          index = len - 1;
        }else{
          index = this.selectedImageIndexValue - 1;
        }
      }
      this.selectedImageIndexValue = index;
      this.imageTarget.style.backgroundImage = `url(${images[index]})`;
    }
  }

  declare readonly imageTarget: HTMLElement;
  declare readonly imageStringValue: String;
  declare selectedImageIndexValue: number;
  declare readonly hasImageStringValue: Boolean;
  declare readonly hasImageTarget: Boolean;
}
