import { Controller } from '@hotwired/stimulus';
import { formatStimulusIdentifier } from 'javascript/lib/utils';

export default class extends Controller {
  static outlets = ['scoring-fields--scoring-scales'];
  static targets = ['yesSelector', 'noSelector'];

  connect() {
    this.element[formatStimulusIdentifier(this.identifier)] = this;
    this.fieldType = document.getElementById(this.element.id).getAttribute('data-field_type_string');
  }

  changeHandler(e) {
    // We handle any side effects to sibling FieldPropertyToggles in FieldPropertyToggle controllers
    if (this.element.id === `scoring-fields--field-property-toggle--for-${this.fieldType}[uses_forced_ranking]`) {
      this.handleForcedRankingChange();
    }
    if (this.element.id === `scoring-fields--field-property-toggle--for-${this.fieldType}[scoring_range_include_na]`) {
      this.handleScoringRangeIncludeNaChange();
    }
    // And then handle any consequent changes to scoring scales in the
    // ScoringScaleController which encapsulates that knowledge
    this.scoringFieldsScoringScalesOutlet.handleFieldPropertyChange(e);
  }

  handleForcedRankingChange() {
    if (this.yesSelectorTarget.checked) {
      document.getElementById(`scoring-fields--field-property-toggle--for-${this.fieldType}[scoring_range_include_na]`).fieldPropertyToggle.disableYesSelector();
    } else {
      document.getElementById(`scoring-fields--field-property-toggle--for-${this.fieldType}[scoring_range_include_na]`).fieldPropertyToggle.enableYesSelector();
    }
  }

  handleScoringRangeIncludeNaChange() {
    if (this.yesSelectorTarget.checked) {
      document.getElementById(`scoring-fields--field-property-toggle--for-${this.fieldType}[uses_forced_ranking]`).fieldPropertyToggle.disableYesSelector();
    } else {
      document.getElementById(`scoring-fields--field-property-toggle--for-${this.fieldType}[uses_forced_ranking]`).fieldPropertyToggle.enableYesSelector();
    }
  }

  disableYesSelector() {
    this.noSelectorTarget.click();
    this.yesSelectorTarget.disabled = true;
    this.noSelectorTarget.disabled = true;
  }

  enableYesSelector() {
    this.yesSelectorTarget.disabled = false;
    this.noSelectorTarget.disabled = false;
  }
}
