import { Controller } from '@hotwired/stimulus';
import CommonDropdownMenuController from 'components/common/dropdown/menu_controller';

export default class extends Controller {
  static targets = ['buttonText'];

  static outlets = ['common--dropdown--menu'];

  static values = {
    buttonValue: String,
  };

  selectItem(event) {
    this.buttonTextTarget.textContent = event.params.buttonText;
    this.closeDropdownMenu();
  }

  closeDropdownMenu() {
    if (this.hasCommonDropdownMenuOutlet) {
      this.commonDropdownMenuOutlets.forEach((el) => {
        el.close();
      });
    }
  }

  declare readonly buttonTextTarget: HTMLSpanElement;
  declare buttonValue: string;
  declare hasCommonDropdownMenuOutlet: boolean;
  declare commonDropdownMenuOutlets: CommonDropdownMenuController[];
}
