import intlTelInput from 'intl-tel-input';
import iso2CountryCodesListByRegion, { Region } from './iso2CountryCodesListByRegion';
import { isFormPreviewMode, fieldValidationMessage, isMobile } from '../utils';

interface InputOptions {
  initialCountry: string,
  onlyCountries: string[],
  preferredCountries: string[],
  utilsScript: string | null,
  localizedCountries?: object,
  hiddenInput?: string,
  allowDropdown?: boolean
}

interface PhoneNumberOptions {
  error_message?: string,
  hidden_field?: boolean,
  region?: Region,
  skip_validation?: boolean,
}

const IntlTelInput = (el: Element, inputOptions: InputOptions) => intlTelInput(
  el,
  inputOptions,
);

let utilsScript: string | null = null;

const phoneNumberFieldName = 'data-js-phone-number-field';

// Query all occurences of phone number fields.
// eslint-disable-next-line func-names
$.onmount('[data-js-phone-number-field]', function () {
  const utilsScriptAttributeName = 'data-iti-utils-script-path';
  const number = this;
  const optionsData: string | null = number.getAttribute(phoneNumberFieldName);
  const options: PhoneNumberOptions | null = optionsData ? JSON.parse(optionsData) : null;

  // We need to grab the digested utils.js script path from a data attribute.
  if (!utilsScript) {
    const utilsScriptPath = document.querySelector(`[${utilsScriptAttributeName}]`);
    if (utilsScriptPath) {
      utilsScript = utilsScriptPath.getAttribute(utilsScriptAttributeName);
    } else {
      // eslint-disable-next-line no-console
      console.error(`IntlTelInput utils.js path not provided. [${utilsScriptAttributeName}].`);
    }
  }

  const inputOptions: InputOptions = {
    initialCountry: window.settings_js.sms_default_country,
    onlyCountries: iso2CountryCodesListByRegion(options?.region),
    preferredCountries: [],
    allowDropdown: !isMobile(),
    utilsScript,
  };

  if (!!window.i18n_js && !!window.i18n_js.phone_number_field) {
    inputOptions.localizedCountries = window.i18n_js.phone_number_field;
  }

  if (options?.hidden_field) {
    inputOptions.hiddenInput = number.getAttribute('name') || undefined;
    number.setAttribute('name', number.id);
  }

  const iti = IntlTelInput(
    number,
    inputOptions,
  );

  // Add all the IntlTelInput methods to the input element.
  const jqueryElement: any = $(number)[0];

  jqueryElement.setNumber = (e) => iti.setNumber(e);
  jqueryElement.setPlaceholderNumberType = (e) => iti.setPlaceholderNumberType(e);
  jqueryElement.getNumber = (e) => iti.getNumber(e);
  jqueryElement.getNumberType = () => iti.getNumberType();
  jqueryElement.getValidationError = () => iti.getValidationError();
  jqueryElement.getSelectedCountryData = () => iti.getSelectedCountryData();
  jqueryElement.PossibleNumber = () => iti.PossibleNumber();
  jqueryElement.isValidNumber = () => iti.isValidNumber();

  // Add Field validation on field after it exists.
  if (!options?.skip_validation && number?.classList?.contains('vidcruiter-validation-phone-number-field')) {
    // Handle a quetionnaire validator differently to have custom error messaging.
    if (!isFormPreviewMode(number)) {
      $(number).rules('add', {
        vidcruiterValidationPhoneNumberField: iti,
        messages: {
          vidcruiterValidationPhoneNumberField: fieldValidationMessage(
            number,
            'this_field_is_required_sms_2',
            window.i18n_js.this_field_is_required_sms,
          ),
        },
      });
    } else {
      $(number).rules('add', {
        vidcruiterValidationPhoneNumberField: iti,
        messages: {
          vidcruiterValidationPhoneNumberField: options?.error_message,
        },
      });
    }
  }
});

window.IntlTelInput = IntlTelInput;
export default IntlTelInput;
