import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'input',
  ];

  static values = {
    filterType: String,
    last: String
  };

  connect() {}

  escape() {
    var initialValue = this.inputTarget.value;
    
    if(initialValue === '') {
      return;
    }
    
    this.inputTarget.value = '';
    
    this.updateFilter();
  }

  enter() {
    this.updateFilter();
  }

  blur() {
    this.updateFilter();
  }

  updateFilter() {
    if(this.lastValue === this.inputTarget.value) {
      return;
    }

    window.updateFilterBy($(this.inputTarget));
    window.sortByUpdate($('.list-filtering'));

    this.lastValue = this.inputTarget.value;
  }

  removeFilter() {
    const filteringContainer = $('.list-filtering');
    const filterByFieldClass = $(this.fieldClassTarget).val();
    const filterByInput = $(filteringContainer.find(`.${filterByFieldClass}`));

    filterByInput.val('');

    window.sortByUpdate($('.list-filtering'));
  }

  resetState() {
    this.inputTarget.value = $(this.inputTarget).data('initial-value');
  }
}
