import { Controller } from '@hotwired/stimulus';
import { interpolateTemplate } from 'javascript/lib/utils';

export default class extends Controller {

  static targets = ['addConditionRowButton','conditionalActionRows', 'conditionalActionRowTemplate'];
  static values = {
    conditionableTypes: Object,
    personFieldAnswerValueFieldOptionSelectors: Object,
  };

  static outlets = ['conditional-actions--condition-row'];

  resetDeleteOptions() {
    const deleteButtons = document.getElementsByClassName('conditional-actions--condition-row-delete-button');
    if (deleteButtons.length !== 0) {
      if (deleteButtons.length === 1) {
        deleteButtons[0].classList.add('disabled');
      } else {
        deleteButtons[0].classList.remove('disabled');
      }
    }
    this.resetConditionalOptionValues();
  }

  handleConditionRowChange() {
    if (this.conditionalActionRowsTarget.children.length >= 3) {
      this.addConditionRowButtonTarget.classList.add('hidden');
    } else {
      this.addConditionRowButtonTarget.classList.remove('hidden');
    }
  }

  resetConditionalOptionValues() {
    const usedConditionalTypes = [];
    // Ensure we only show 1 of each of the three possible conditionable_types
    Array.prototype.forEach.call(this.conditionalActionsConditionRowOutlets, (el) => {
      el.conditionableTypeSelectorTargets.forEach(selector => {
          usedConditionalTypes.push(parseInt(selector.value));
      });
    });

    Array.prototype.forEach.call(this.conditionalActionsConditionRowOutlets, (el) => {
      el.conditionableTypeSelectorTargets.forEach(selector => {
        Array.prototype.forEach.call(selector.querySelectorAll('option'), (optionEl) => {
          if (optionEl.value !== selector.value) {
            if (usedConditionalTypes.includes(parseInt(optionEl.value))) {
              selector.querySelector('option[value="'+optionEl.value+'"]').disabled = true;
              selector.querySelector('option[value="'+optionEl.value+'"]').selected = false;
            } else {
              selector.querySelector('option[value="'+optionEl.value+'"]').disabled = false;
            }
          }
        });
      });
    });
  }

  enableConditionalActionOptionValue(value) {
    this.conditionalActionsConditionRowOutlets.forEach(function (conditionalActionsConditionRowOutlet) {
      if (conditionalActionsConditionRowOutlet.conditionableTypeSelectorTarget.value != value) {
        conditionalActionsConditionRowOutlet.conditionableTypeSelectorTarget.querySelector('option[value="'+value+'"]').disabled = false;
      }
    });
  }

  disableConditionalActionOptionValue(value) {
    this.conditionalActionsConditionRowOutlets.forEach(function (conditionalActionsConditionRowOutlet) {
      if (conditionalActionsConditionRowOutlet.conditionableTypeSelectorTarget.value == value) {
        conditionalActionsConditionRowOutlet.conditionableTypeSelectorTarget.querySelector('option[value="'+value+'"]').disabled = true;
        conditionalActionsConditionRowOutlet.conditionableTypeSelectorTarget.querySelector('option[value="'+value+'"]').selected = false;
      }
    });
  }

  addConditionRow() {
    const disabledConditionalTypes = [];
    // Ensure we only show 1 of each of the three possible conditionable_types
    Array.prototype.forEach.call(this.conditionalActionsConditionRowOutlets, (el) => {
      el.conditionableTypeSelectorTargets.forEach(selector => {
        disabledConditionalTypes.push(parseInt(selector.value));
      });
    });
    const newIndex = this.conditionalActionRowsTarget.children.length;
    const data = { index: newIndex };
    const clone = this.conditionalActionRowTemplateTarget.content.firstElementChild.cloneNode(true);
    const node = interpolateTemplate(clone.outerHTML, data);
    this.conditionalActionRowsTarget.insertAdjacentHTML('beforeend', node);

    // Ensure this has all the extant selected conditionable_types disabled
    setTimeout(() => {
      const newRow = this.conditionalActionsConditionRowOutlets[this.conditionalActionsConditionRowOutlets.length-1];
      disabledConditionalTypes.forEach(function (disabledConditionalTypeId) {
        newRow.conditionableTypeSelectorTarget.querySelector('option[value="'+disabledConditionalTypeId+'"]').disabled = true;
        newRow.conditionableTypeSelectorTarget.querySelector('option[value="'+disabledConditionalTypeId+'"]').selected = false;
      });
      // Now ensure no other selector has the new selectors value selected
      this.conditionalActionsConditionRowOutlets.forEach(function (conditionalActionsConditionRowOutlet) {
        if (conditionalActionsConditionRowOutlet.conditionableTypeSelectorTarget.value != newRow.conditionableTypeSelectorTarget.value) {
          conditionalActionsConditionRowOutlet.conditionableTypeSelectorTarget.querySelector('option[value="'+newRow.conditionableTypeSelectorTarget.value+'"]').disabled = true;
          conditionalActionsConditionRowOutlet.conditionableTypeSelectorTarget.querySelector('option[value="'+newRow.conditionableTypeSelectorTarget.value+'"]').selected = false;
        }
      });
    },1);

    this.resetDeleteOptions();
    this.handleConditionRowChange();

  }
}
