import UtilsRevealContentController from 'components/utils/reveal/content_controller';
import { Controller } from '@hotwired/stimulus';

export default class UtilsRevealCheckboxController extends Controller {
  static targets = ['checkbox'];
  static outlets = ['utils--reveal--content'];

  outletName: string | null | undefined;

  connect(): void {
    this.outletName = this.element.getAttribute(
      'data-utils--reveal--button-utils--reveal--content-outlet',
    );

    /* Toggle on connect so state of checkbox is reflective of state of oulet */
    this.toggle();
  }

  toggle(): void {
    if (!this.hasUtilsRevealContentOutlet) {
      console.error(`Missing outlet "${this.outletName}"`);
      return;
    }

    if (this.checkboxTarget.checked) {
      this.utilsRevealContentOutlets.forEach((outlet) => {
        outlet.show();
      });
    } else {
      this.utilsRevealContentOutlets.forEach((outlet) => {
        outlet.hide();
      });
    }
  }

  declare checkboxTarget: HTMLInputElement;
  declare hasUtilsRevealContentOutlet: boolean;
  declare utilsRevealContentOutlets: UtilsRevealContentController[];
}
