import { Controller } from '@hotwired/stimulus';
import { assetPath } from 'javascript/lib/utils';

const bmpIcon = require('./file_extension_icons/bmp.svg') as string;
const docIcon = require('./file_extension_icons/doc.svg') as string;
const docxIcon = require('./file_extension_icons/docx.svg') as string;
const emlIcon = require('./file_extension_icons/eml.svg') as string;
const jpegIcon = require('./file_extension_icons/jpeg.svg') as string;
const jpgIcon = require('./file_extension_icons/jpg.svg') as string;
const msgIcon = require('./file_extension_icons/msg.svg') as string;
const otdIcon = require('./file_extension_icons/otd.svg') as string;
const pbixIcon = require('./file_extension_icons/pbix.svg') as string;
const pdfIcon = require('./file_extension_icons/pdf.svg') as string;
const pngIcon = require('./file_extension_icons/png.svg') as string;
const pptIcon = require('./file_extension_icons/ppt.svg') as string;
const pptxIcon = require('./file_extension_icons/pptx.svg') as string;
const rtfIcon = require('./file_extension_icons/rtf.svg') as string;
const tifIcon = require('./file_extension_icons/tif.svg') as string;
const txtIcon = require('./file_extension_icons/txt.svg') as string;
const wpsIcon = require('./file_extension_icons/wps.svg') as string;
const xlsIcon = require('./file_extension_icons/xls.svg') as string;
const xlsxIcon = require('./file_extension_icons/xlsx.svg') as string;
const zipIcon = require('./file_extension_icons/zip.svg') as string;
const unknownIcon = require('./file_extension_icons/unknown.svg') as string;

const extensionMap = {
  bmp: bmpIcon,
  doc: docIcon,
  docx: docxIcon,
  eml: emlIcon,
  jpeg: jpegIcon,
  jpg: jpgIcon,
  msg: msgIcon,
  otd: otdIcon,
  pbix: pbixIcon,
  pdf: pdfIcon,
  png: pngIcon,
  ppt: pptIcon,
  pptx: pptxIcon,
  rtf: rtfIcon,
  tif: tifIcon,
  txt: txtIcon,
  wps: wpsIcon,
  xls: xlsIcon,
  xlsx: xlsxIcon,
  zip: zipIcon,
  unknown: unknownIcon,
};

export default class extends Controller {
  static targets = ['fileExtensionIcon'];
  static values = { ext: String };

  fileExtensionIconTargetConnected() {
    if (this.extValue in extensionMap) {
      this.fileExtensionIconTarget.setAttribute('src', assetPath(extensionMap[this.extValue]));
    } else {
      this.fileExtensionIconTarget.setAttribute('src', assetPath(extensionMap.unknown));
    }
  }

  declare readonly fileExtensionIconTarget: HTMLImageElement;
  declare extValue: string;
}
