import { Controller } from '@hotwired/stimulus';
import { useHotkeys } from 'stimulus-use/hotkeys';
import CommonMenuListItem from './list_item_controller';

export default class CommonMenuVerticalMenu extends Controller {
  static outlets = ['common--menu--list-item'];
  static targets = ['search', 'item', 'noResults'];

  // The currently focused tab
  tabFocusIndex: number = 0;

  initialize() {
    // set initial focused tab index state to the currently active tab, or the first item if no tab is active
    if (this.activeTabIndex() === -1) {
      this.tabFocusIndex = 0;
    } else {
      this.tabFocusIndex = this.activeTabIndex();
    }
  }

  connect() {
    // set up accessible hotkeys
    useHotkeys(this, {
      up: [this.handleUpArrowKeyPress, this.element as HTMLElement],
      down: [this.handleDownArrowKeyPress, this.element as HTMLElement],
      space: [this.handleSpaceKeyPress, this.element as HTMLElement],
      home: [this.handleHomeKeyPress, this.element as HTMLElement],
      end: [this.handleEndKeyPress, this.element as HTMLElement],
    });
  }

  /**
   * Actions
   */
  setAllTabsInactive() {
    this.commonMenuListItemOutlets.forEach((el) => {
      el.setInactive();
    });
  }

  search() {
    const input = this.searchTarget.value.toLowerCase();

    this.itemTargets.forEach((item) => {
      if (
        item.dataset?.itemName?.toLowerCase().includes(input)
        // Uncomment if we want to keep the active tab visible at all times
        // || item.children[0].getAttribute('data-active')
      ) {
        CommonMenuVerticalMenu.showItem(item);
      } else {
        CommonMenuVerticalMenu.hideItem(item);
      }
    });

    this.showNoResults();
  }

  /**
   * Event Handlers
   */
  handleUpArrowKeyPress(e) {
    e.preventDefault();
    this.commonMenuListItemOutletElements[this.tabFocusIndex].setAttribute('tabindex', '-1');

    const currentIndexInVisibleItems = this.allVisibleItemIndexes().findIndex(
      (i) => i === this.tabFocusIndex,
    );

    if (currentIndexInVisibleItems > 0) {
      this.tabFocusIndex = this.allVisibleItemIndexes()[currentIndexInVisibleItems - 1] as number;
      this.commonMenuListItemOutletElements[this.tabFocusIndex].setAttribute('tabindex', '0');
      this.commonMenuListItemOutletElements[this.tabFocusIndex].focus();
    }
  }

  handleDownArrowKeyPress(e) {
    e.preventDefault();
    this.commonMenuListItemOutletElements[this.tabFocusIndex].setAttribute('tabindex', '-1');

    const currentIndexInVisibleItems = this.allVisibleItemIndexes().findIndex(
      (i) => i === this.tabFocusIndex,
    );

    if (currentIndexInVisibleItems < this.allVisibleItemIndexes().length - 1) {
      this.tabFocusIndex = this.allVisibleItemIndexes()[currentIndexInVisibleItems + 1] as number;
      this.commonMenuListItemOutletElements[this.tabFocusIndex].setAttribute('tabindex', '0');
      this.commonMenuListItemOutletElements[this.tabFocusIndex].focus();
    }
  }

  handleSpaceKeyPress(e) {
    e.preventDefault();
    this.commonMenuListItemOutletElements[this.tabFocusIndex].click();
  }

  handleHomeKeyPress(e) {
    e.preventDefault();
    this.commonMenuListItemOutletElements[this.tabFocusIndex].setAttribute('tabindex', '-1');
    this.tabFocusIndex = this.firstVisibleItemIndex();
    this.commonMenuListItemOutletElements[this.tabFocusIndex].setAttribute('tabindex', '0');
    this.commonMenuListItemOutletElements[this.tabFocusIndex].focus();
  }

  handleEndKeyPress(e) {
    e.preventDefault();
    this.commonMenuListItemOutletElements[this.tabFocusIndex].setAttribute('tabindex', '-1');
    this.tabFocusIndex = this.lastVisibleItemIndex();
    this.commonMenuListItemOutletElements[this.tabFocusIndex].setAttribute('tabindex', '0');
    this.commonMenuListItemOutletElements[this.tabFocusIndex].focus();
  }

  /**
   * Helpers
   */
  showNoResults() {
    const itemsCount = this.itemTargets.length;
    const hiddenCount = this.itemTargets.filter((el) => el.classList.contains('hidden')).length;

    if (itemsCount === hiddenCount) {
      this.noResultsTarget.classList.remove('hidden');
    } else {
      this.noResultsTarget.classList.add('hidden');
      this.updateFocusIndexAfterSearch();
    }
  }

  updateFocusIndexAfterSearch() {
    this.commonMenuListItemOutletElements[this.tabFocusIndex].setAttribute('tabindex', '-1');

    const activeTabIndex = this.itemTargets.findIndex(
      (item) => item.children[0]?.getAttribute('aria-selected') === 'true',
    );

    // if there is a visible active tab, set tabindex to that
    if (activeTabIndex !== -1 && !this.itemTargets[activeTabIndex].classList.contains('hidden')) {
      this.tabFocusIndex = activeTabIndex;
    } else {
      // otherwise set tabindex to the first visible item in the list
      this.tabFocusIndex = this.firstVisibleItemIndex();
    }

    this.commonMenuListItemOutletElements[this.tabFocusIndex].setAttribute('tabindex', '0');
  }

  static hideItem(item) {
    item.classList.add('hidden');
  }

  static showItem(item) {
    item.classList.remove('hidden');
  }

  /**
   * Computed properties
   */
  activeTabIndex() {
    return this.commonMenuListItemOutletElements.findIndex(
      (el) => el.getAttribute('aria-selected') === 'true',
    );
  }

  allVisibleItemIndexes() {
    return this.itemTargets
      .map((el, i) => (!el.classList.contains('hidden') ? i : null))
      .filter((i) => i !== null);
  }

  firstVisibleItemIndex() {
    return this.itemTargets.findIndex((el) => !el.classList.contains('hidden'));
  }

  lastVisibleItemIndex() {
    return this.itemTargets.findLastIndex((el) => !el.classList.contains('hidden'));
  }

  declare commonMenuListItemOutlets: CommonMenuListItem[];
  declare commonMenuListItemOutletElements: HTMLElement[];
  declare itemTargets: HTMLLIElement[];
  declare searchTarget: HTMLInputElement;
  declare noResultsTarget: HTMLLIElement;
  declare hasSearchTarget: boolean;
}
