import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["timezoneLink", "timezoneWrapper", "datetimepicker", "timezoneSelect"]

  static values = {
    dateFormat: String,
    timeFormat: String,
  }

  connect() {
    // Set up the datetimepicker
    $(this.datetimepickerTarget).datetimepicker({
      showOtherMonths:   true,
      selectOtherMonths: true,
      dateFormat:        this.dateFormatValue,
      timeFormat:        this.timeFormatValue,
      controlType:       'select',
      numberOfMonths:    2,
      width:             500,
      beforeShow:        function(input, inst) {
        // Handle calendar position before showing it.
        // It's not supported by Datepicker itself (for now) so we need to use its internal variables.
        var calendar = inst.dpDiv;

        // Dirty hack, but we can't do anything without it (for now, in jQuery UI 1.8.20)
        setTimeout(function() {
          calendar.addClass('calendar-v3');
          calendar.addClass('calendar-v3-double');
          calendar.position({
            my: 'left top',
            at: 'left bottom',
            collision: 'none',
            of: input
          });
        }, 1);
  	  }
    });
  }

  showTimezoneWrapper(event) {
    event.preventDefault();
    this.timezoneLinkTarget.classList.add('hidden');
    this.timezoneWrapperTarget.classList.remove('hidden');
  }

  setNow(event) {
    event.preventDefault();
    const timeZone = this.timezoneSelectTarget.value;
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');


    fetch('/live-interview-set-now', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken || '',
      },
      body: JSON.stringify({ time_zone: timeZone })
    })
    .then(response => response.json())
    .then(data => {
      $(this.datetimepickerTarget).datetimepicker('setDate', new Date(data.date));
    });
  }

  onTimeZoneChange() {
    $(this.datetimepickerTarget).valid();
  }

  declare datetimepickerTarget: HTMLElement;
  declare timezoneSelectTarget: HTMLSelectElement;
  declare timezoneLinkTarget: HTMLElement;
  declare timezoneWrapperTarget: HTMLElement;
}
