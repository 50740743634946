import { Controller } from '@hotwired/stimulus';
import { useDebounce, useIntersection } from 'stimulus-use';

export default class extends Controller {
  static targets = ['input', 'frame', 'nextPage', 'searchIcon', 'loadingIcon'];

  static values = {
    url: String,
    autofocus: Boolean,
  };

  static debounces = ['search'];

  connect() {
    useDebounce(this, { wait: 300 });
    useIntersection(this);
  }

  appear() {
    if (this.autofocusValue) {
      this.inputTarget.focus();
    }
  }

  async search() {
    const terms = this.inputTarget.value.toLowerCase();

    if (terms.length === 0 || terms.length >= 3) {
      this.showLoadingIcon();
      this.disableNextPage();
      this.clearResults();
      await this.fetchResults(terms);
      this.showSearchIcon();
    }
  }

  clearResults() {
    this.frameTarget.innerHTML = '';
  }

  disableNextPage() {
    this.nextPageTarget.setAttribute('disabled', '');
  }

  enableNextPage() {
    this.nextPageTarget.removeAttribute('disabled');
  }

  showLoadingIcon() {
    this.searchIconTarget.classList.add('hidden');
    this.loadingIconTarget.classList.remove('hidden');
  }

  showSearchIcon() {
    this.loadingIconTarget.classList.add('hidden');
    this.searchIconTarget.classList.remove('hidden');
  }

  async fetchResults(terms?: string) {
    const parsedUrl = new URL(`${window.location.origin}/${this.urlValue}`);

    if (terms) {
      parsedUrl.searchParams.append('terms', terms);
    }

    await fetch(parsedUrl, {
      headers: {
        'Content-Type': 'text/vnd.turbo-stream.html',
      },
    }).then((response) => response.text())
      .then((html) => window.Turbo.renderStreamMessage(html));
  }

  declare readonly inputTarget: HTMLInputElement;
  declare readonly frameTarget: HTMLElement;
  declare readonly nextPageTarget: HTMLElement;
  declare readonly searchIconTarget: HTMLElement;
  declare readonly loadingIconTarget: HTMLElement;
  declare urlValue: string;
  declare autofocusValue: boolean;
}
