import { Controller } from '@hotwired/stimulus';
import { assetPath } from 'javascript/lib/utils';

const vidCruiterLogo = require('./vidcruiter_logo_white.svg') as string;
const vidProfileLogo = require('./vid_profile_logo.svg') as string;
const vueProfileLogo = require('./in_vue_profile_logo.svg') as string;

export default class extends Controller {
  static targets = ['logo'];
  static values = {
    logoType: String,
  };

  // Set the correct logo src based on the current company
  logoTargetConnected() {
    let src = vidCruiterLogo;
    if (this.logoTypeValue === 'vueprofile') {
      src = vueProfileLogo;
    } else if (this.logoTypeValue === 'vidprofile') {
      src = vidProfileLogo;
    }
    this.logoTarget.classList.remove('hidden');
    this.logoTarget.setAttribute('src', assetPath(src));
  }

  declare readonly logoTarget: HTMLImageElement;
  declare readonly logoTypeValue: String;
}
