import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['selectAll', 'deselectAll', 'selectElement'];

  static values = {
    remote: Object, localization: String, selected: Array,
    multiple: Boolean, clearable: Boolean, searchable: Boolean, renderers: Object,
    containerClasses: String, fullWidthItems: Boolean, type: String, companyId: Number,
    selectable: Boolean, createable: Boolean, selectionIcon: String, data: Object,
    dropdownParent: String, dropdownClasses: String, dropdownInput: Boolean,
    aria: Object, inlineResults: Boolean, skipAutoSync: Boolean, searchField: Array,
  };

  connect() {
    // Prevent multiple initialization
    if(!!this.selectElementTarget.tomselect) {
      this.tomselect = this.selectElementTarget.tomselect;
    } else {
      const advancedSelect = new AdvancedSelect(this.selectElementTarget, {
        remote:           this.remoteValue,
        selected:         this.selectedValue,
        localization:     JSON.parse(this.localizationValue),
        multiple:         this.multipleValue,
        clearable:        this.clearableValue,
        searchable:       this.searchableValue,
        renderers:        this.renderersValue,
        containerClasses: this.containerClassesValue,
        dropdownParent:   this.dropdownParentValue,
        dropdownClasses:  this.dropdownClassesValue,
        fullWidthItems:   this.fullWidthItemsValue,
        type:             this.typeValue,
        companyId:        this.companyIdValue,
        selectable:       this.selectableValue,
        createable:       this.createableValue,
        selectionIcon:    this.selectionIconValue,
        data:             this.dataValue,
        dropdownInput:    this.dropdownInputValue,
        aria:             this.ariaValue,
        inlineResults:    this.inlineResultsValue,
        skipAutoSync:     this.skipAutoSyncValue,
        searchField:      this.searchFieldValue,
      });
      
      this.tomselect = advancedSelect.tomselect;
    }
  }

  selectAll(event) {
    this.selectElementTarget.tomselect.addItem(this.selectElementTarget.tomselect.options);    
  }

  deselectAll(event) {
    this.selectElementTarget.tomselect.clear();
  }
  
  declare readonly companyIdValue: number;
  declare readonly typeValue: string;
  declare readonly remoteValue: object;
  declare readonly selectedValue: object;
  declare readonly localizationValue: string;
  declare readonly multipleValue: boolean;
  declare readonly selectableValue: boolean;
  declare readonly clearableValue: boolean;
  declare readonly searchableValue: boolean;
  declare readonly renderersValue: object;
  declare readonly containerClassesValue: string;
  declare readonly dropdownParentValue: string;
  declare readonly dropdownClassesValue: string;
  declare readonly selectAllTarget: HTMLLinkElement;
  declare readonly deselectAllTarget: HTMLLinkElement;
  declare readonly selectElementTarget: HTMLElement;
  declare readonly fullWidthItemsValue: boolean;
  declare readonly createableValue: boolean;
  declare readonly selectionIconValue: string;
  declare readonly dataValue: object;
  declare readonly dropdownInputValue: boolean;
  declare readonly ariaValue: object;
  declare readonly inlineResultsValue: boolean;
  declare readonly skipAutoSyncValue: boolean;
  declare readonly searchFieldValue: object;
}
