import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    options: Object,
  };

  static targets = ['link', 'contentTemplate'];

  connect() {
    const options = this.optionsValue;
    const content = this.contentTemplateTarget.content.cloneNode(true);

    const position = { my: 'top center', at: 'bottom center', viewport: $(window) };
    if (options.my) { position.my = options.my; }
    if (options.at) { position.at = options.at; }

    $(this.linkTarget).qtip({
      content: { text: content, title: (options.title || false) },
      position,
      hide: {
        fixed: true,
        delay: 400,
      },
      events: {
        show: () => {
          $('*').qtip('hide');
        },
      },
      style: {
        classes: 'ui-tooltip-v2',
      },
    });
  }
}
