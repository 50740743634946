import { Controller } from '@hotwired/stimulus';

export default class NotificationBadgeController extends Controller {
  static values = {
    unreadIds: { type: Array, default: [] },
  };

  removeNotification(id: number) {
    this.unreadIdsValue = this.unreadIdsValue.filter((item) => item !== id);
  }

  unreadIdsValueChanged() {
    this.updateNotificationElement();
  }

  updateNotificationElement() {
    if (this.notificationCount >= 1) {
      this.element.classList.remove('hidden');
    } else {
      this.element.classList.add('hidden');
    }
    this.element.innerHTML = this.notificationCount.toString();
  }

  get notificationCount() {
    return this.unreadIdsValue.length;
  }

  declare unreadIdsValue: number[];
}
