import { Controller } from '@hotwired/stimulus';

export default class AuthFieldsLocaleController extends Controller {
  static targets = ['input'];
  static values = { path: String };

  change() {
    let path = window.location.origin + window.location.pathname;

    if (this.pathValue) {
      path = this.pathValue;
    }

    window.location.href = `${path}?locale=${this.inputTarget.value}`;
  }

  declare readonly inputTarget: HTMLInputElement;
  declare pathValue: string;
}
