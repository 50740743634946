import { Application } from '@hotwired/stimulus';
// eslint-disable-next-line import/no-unresolved
import { definitions } from 'stimulus:../../components';

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

// Register components
application.load(definitions);

export default application;
