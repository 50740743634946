import { Controller } from '@hotwired/stimulus';

export default class CommonMenuListItem extends Controller {
  static classes = ['active', 'inactive'];

  setInactive() {
    this.removeSelectedClasses(this.element);
    this.ariaDeselect(this.element);
  }

  setActive() {
    this.addSelectedClasses(this.element);
    this.ariaSelect(this.element);
  }

  removeSelectedClasses(element) {
    element.classList.remove(...this.activeClasses);
    element.classList.add(...this.inactiveClasses);
  }

  addSelectedClasses(element) {
    element.classList.remove(...this.inactiveClasses);
    element.classList.add(...this.activeClasses);
  }

  ariaSelect(element) {
    element.setAttribute('aria-selected', 'true');
    element.setAttribute('tabindex', '0');
    element.focus();
  }

  ariaDeselect(element) {
    element.setAttribute('aria-selected', 'false');
    element.setAttribute('tabindex', '-1');
    element.blur();
  }

  declare readonly activeClasses: string;
  declare readonly inactiveClasses: string;
}
