import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';
import { useHotkeys } from 'stimulus-use/dist/hotkeys';

export default class extends Controller {
  static targets = ['content', 'button'];

  connect() { 
    useClickOutside(this, { element: this.contentTarget });
    useHotkeys(this, {
      esc: [this.close],
      tab: [this.close],  // Close on focus shift
      'shift+tab': [this.close],  // Close on focus shift
      enter: [this.open],
      space: [this.open],
      down: [this.open],
    });
  }

  /**
   * Closes the dropdown when clicking outside the dropdown area.
   * @param {Event} e - The click event.
   */
  clickOutside(e) {
    if (this.element.contains(e.target)) return; // Ignore clicks inside the dropdown.
    this.close();
  }

  /**
   * Toggles the dropdown state based on the button's aria-expanded value.
   * Only toggles if the click is on the button or within valid boundaries.
   * @param {Event} e - The click event.
   */
  toggle(e) {
    const isExpanded = this.buttonTarget.ariaExpanded === 'true';
    const isValidClick = e.target === this.element || e.target === this.contentTarget;

    if (isExpanded && isValidClick) {
      return this.close();
    }
    return this.open();
  }

  /**
   * Opens the dropdown and ensures proper event handling for nested components.
   */
  open() {
    if (document.activeElement === this.buttonTarget) { 
      this.contentTarget.classList.remove('hidden', 'content-visibility-hidden');
      this.buttonTarget.ariaExpanded = 'true';

      // Allow nested listeners to detect the initial state before dropdown fully opens.
      setTimeout(() => {
        this.buttonTarget.setAttribute('data-is-expanded', 'true');
      }, 300);
    }
  }

  /**
   * Closes the dropdown and resets relevant attributes.
   */
  close() {
    this.contentTarget.classList.add('hidden', 'content-visibility-hidden');
    this.buttonTarget.ariaExpanded = 'false';
    this.buttonTarget.setAttribute('data-is-expanded', 'false');
  }

  // Declare types for contentTarget and buttonTarget to enable type safety (optional).
  declare readonly contentTarget: HTMLElement;
  declare readonly buttonTarget: HTMLElement;
}