import { dom, library } from '@fortawesome/fontawesome-svg-core';

import {
  faUsersGear as fasUsersGear,
  faSpinner,
  faXmark as fasXmark,
  faCloudArrowUp as fasCloudArrowUp,
  faDownLeftAndUpRightToCenter as fasDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter as fasUpRightAndDownLeftFromCenter,
  faEllipsisVertical as fasEllipsisVertical,
  faLink as fasLink,
  faCheckCircle as fasCheckCircle,
  faExclamationCircle as fasExclamationCircle,
  faQuestionCircle as fasQuestionCircle,
  faChevronDown as fasChevronDown,
  faChevronRight as fasChevronRight,
  faFolderOpen as fasFolderOpen,
  faPlay as fasPlay,
  faEyeSlash as fasEyeSlash,
  faInfoCircle as fasInfoCircle,
  faChevronLeft as fasChevronLeft,
  faChevronUp as fasChevronUp,
  faExclamationTriangle as fasExclamationTriangle,
  faCircle as fasCircle,
  faKey as fasKey,
  faFolderTimes as fasFolderTimes,
  faGrid2 as fasGrid2,
  faMagnifyingGlass as fasMagnifyingGlass,
  faArrowSquareRight as fasArrowSquareRight,
  faClipboardListCheck as fasClipboardListCheck,
  faHouse as fasHouse,
  faCalendarDays as fasCalendarDays,
  faChartBar as fasChartBar,
  faChartColumn as fasChartColumn,
  faBell as fasBell,
  faSpinnerThird as fasSpinnerThird,
  faGear as fasGear,
  faClock as fasClock,
  faMinusCircle as fasMinusCircle,
  faPlusCircle as fasPlusCircle,
  faEnvelope as fasEnvelope,
  faComment as fasComment,
  faPlus as fasPlus,
  faFolder as fasFolder,
  faBadgeCheck as fasBadgeCheck,
  faTimesCircle as fasTimesCircle,
  faCheck as fasCheck,
  faPlusSquare as fasPlusSquare,
  faMinusSquare as fasMinusSquare,
  faCalendar as fasCalendar,
  faCheckSquare as fasCheckSquare,
  faTrash as fasTrash,
  faArrowLeft as fasArrowLeft,
  faCog as fasCog,
  faPencil as fasPencil,
  faTags as fasTags,
  faDownload as fasDownload,
  faPenSquare as fasPenSquare,
  faDoNotEnter as fasDoNotEnter,
  faThList as fasThList,
  faStar as fasStar,
  faListAlt as fasListAlt,
  faArrowsV as fasArrowsV,
  faUser as fasUser,
  faFileCheck as fasFileCheck,
  faRetweet as fasRetweet,
  faWrench as fasWrench,
  faFileExport as fasFileExport,
  faFilter as fasFilter,
  faShare as fasShare,
  faSearch as fasSearch,
  faGlobe as fasGlobe,
  faGlobePointer as fasGlobePointer,
  faList as fasList,
  faTasks as fasTasks,
  faRedoAlt as fasRedoAlt,
  faDesktop as fasDesktop,
  faFolderTree as fasFolderTree,
  faFileAlt as fasFileAlt,
  faChevronDoubleRight as fasChevronDoubleRight,
  faEye as fasEye,
  faLongArrowRight as fasLongArrowRight,
  faUsersClass as fasUsersClass,
  faFilm as fasFilm,
  faClone as fasClone,
  faCalendarAlt as fasCalendarAlt,
  faEdit as fasEdit,
  faExclamationSquare as fasExclamationSquare,
  faExternalLink as fasExternalLink,
  faTimes as fasTimes,
  faLock as fasLock,
  faUserCheck as fasUserCheck,
  faArrowUp as fasArrowUp,
  faArrowDown as fasArrowDown,
  faBriefcase as fasBriefcase,
  faCopy as fasCopy,
  faUsers as fasUsers,
  faLongArrowLeft as fasLongArrowLeft,
  faInfo as fasInfo,
  faUserLock as fasUserLock,
  faShieldCheck as fasShieldCheck,
  faUpload as fasUpload,
  faAlarmClock as fasAlarmClock,
  faFileDownload as fasFileDownload,
  faClosedCaptioning as fasClosedCaptioning,
  faTag as fasTag,
  faCaretDown as fasCaretDown,
  faSync as fasSync,
  faClipboardCheck as fasClipboardCheck,
  faLayerPlus as fasLayerPlus,
  faThLarge as fasThLarge,
  faMinus as fasMinus,
  faHeadphones as fasHeadphones,
  faHomeAlt as fasHomeAlt,
  faPhone as fasPhone,
  faPowerOff as fasPowerOff,
  faWebcam as fasWebcam,
  faMicrophone as fasMicrophone,
  faWebcamSlash as fasWebcamSlash,
  faVolumeUp as fasVolumeUp,
  faVolumeMute as fasVolumeMute,
  faPhoneAlt as fasPhoneAlt,
  faFile as fasFile,
  faChevronDoubleLeft as fasChevronDoubleLeft,
  faExpand as fasExpand,
  faCompress as fasCompress,
  faMicrophoneSlash as fasMicrophoneSlash,
  faVolume as fasVolume,
  faVolumeSlash as fasVolumeSlash,
  faExpandAlt as fasExpandAlt,
  faCompressAlt as fasCompressAlt,
  faVideo as fasVideo,
  faPhonePlus as fasPhonePlus,
  faCaretUp as fasCaretUp,
  faVideoPlus as fasVideoPlus,
  faPhotoVideo as fasPhotoVideo,
  faCogs as fasCogs,
  faUsersCog as fasUsersCog,
  faQuestion as fasQuestion,
  faEllipsisH as fasEllipsisH,
  faScrubber as fasScrubber,
  faBringFront as fasBringFront,
  faListOl as fasListOl,
  faCalendarStar as fasCalendarStar,
  faEnvelopeOpenText as fasEnvelopeOpenText,
  faArchive as fasArchive,
  faHeadSideBrain as fasHeadSideBrain,
  faPaperclip as fasPaperclip,
  faPrint as fasPrint,
  faComments as fasComments,
  faShareSquare as fasShareSquare,
  faMapMarker as fasMapMarker,
  faSignIn as fasSignIn,
  faUserEdit as fasUserEdit,
  faBan as fasBan,
  faArrowCircleLeft as fasArrowCircleLeft,
  faSquare as fasSquare,
  faBarChart as fasBarChart,
  faFilePdf as fasFilePdf,
  faFileSpreadsheet as fasFileSpreadsheet,
  faFileCsv as fasFileCsv,
  faUserHeadset as fasUserHeadset,
  faServer as fasServer,
  faWifi as fasWifi,
  faPhoneLaptop as fasPhoneLaptop,
  faInfoSquare as fasInfoSquare,
  faClipboardList as fasClipboardList,
  faArrowRight as fasArrowRight,
  faCloudDownloadAlt as fasCloudDownloadAlt,
  faBarcodeScan as fasBarcodeScan,
  faKeyboard as fasKeyboard,
  faPlayCircle as fasPlayCircle,
  faStopCircle as fasStopCircle,
  faMask as fasMask,
  faW as fasW,
  faBolt as fasBolt,
  faLayerGroup as fasLayerGroup,
  faDiamond as fasDiamond,
  faSparkles as fasSparkles,
  faIdCard as fasIDCard,
  faCalendarDay as fasCalendarDay,
  faLanguage as fasLanguage,
  faLaptopMobile as fasLaptopMobile,
  faLocationDot as fasLocationDot,
  faStop as fasStop,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faCloudArrowUp as farCloudArrowUp,
  faArrowRotateLeft as farArrowRotateLeft,
  faExclamationTriangle as farExclamationTriangle,
  faArrowSquareRight as farArrowSquareRight,
  faBadgeCheck as farBadgeCheck,
  faStar as farStar,
  faGlobe as farGlobe,
  faRedoAlt as farRedoAlt,
  faInfoCircle as farInfoCircle,
  faExclamationCircle as farExclamationCircle,
  faUsersClass as farUsersClass,
  faCalendarAlt as farCalendarAlt,
  faFolder as farFolder,
  faFolderTree as farFolderTree,
  faTimes as farTimes,
  faCog as farCog,
  faCopy as farCopy,
  faPlusCircle as farPlusCircle,
  faMinusCircle as farMinusCircle,
  faClock as farClock,
  faExternalLink as farExternalLink,
  faAlarmClock as farAlarmClock,
  faCheck as farCheck,
  faTrash as farTrash,
  faLayerPlus as farLayerPlus,
  faBell as farBell,
  faPlus as farPlus,
  faWebcam as farWebcam,
  faMicrophone as farMicrophone,
  faRetweet as farRetweet,
  faMicrophoneSlash as farMicrophoneSlash,
  faPhonePlus as farPhonePlus,
  faWebcamSlash as farWebcamSlash,
  faVideoPlus as farVideoPlus,
  faVideo as farVideo,
  faPhotoVideo as farPhotoVideo,
  faCogs as farCogs,
  faUsersCog as farUsersCog,
  faQuestion as farQuestion,
  faEllipsisH as farEllipsisH,
  faLongArrowLeft as farLongArrowLeft,
  faLongArrowRight as farLongArrowRight,
  faArrowCircleLeft as farArrowCircleLeft,
  faList as farList,
  faUserHeadset as farUserHeadset,
  faShieldCheck as farShieldCheck,
  faCalendarDays as farCalendarDays,
  faUserEdit as farUserEdit,
  faClipboardList as farClipboardList,
  faEye as farEye,
  faEyeSlash as farEyeSlash,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faClipboardListCheck as falClipboardListCheck,
  faCalendar as falCalendar,
  faFileCheck as falFileCheck,
  faChevronDoubleRight as falChevronDoubleRight,
  faInfoCircle as falInfoCircle,
  faLock as falLock,
  faUserCheck as falUserCheck,
  faQuestionCircle as falQuestionCircle,
  faTimes as falTimes,
  faExclamationTriangle as falExclamationTriangle,
  faMicrophone as falMicrophone,
  faWebcam as falWebcam,
  faServer as falServer,
  faWifi as falWifi,
  faPhoneLaptop as falPhoneLaptop,
  faUserHeadset as falUserHeadset,
  faCheckSquare as falCheckSquare,
  faInfoSquare as falInfoSquare,
  faClipboardList as falClipboardList,
  faVolumeUp as falVolumeUp,
  faCloudDownloadAlt as falCloudDownloadAlt,
  faBarcodeScan as falBarcodeScan,
  faKeyboard as falKeyboard,
  faClock as falClock,
  faTimesCircle as falTimesCircle,
  faUsers as falUsers,
  faRectangleList as falRectangleList,
  faFlag as falFlag,
  faHeadphones as falHeadphones,
} from '@fortawesome/pro-light-svg-icons';

import {
  faHeadphonesSimple as fadHeadphonesSimple,
  faAperture as fadAperture,
  faInfoCircle as fadInfoCircle,
  faCheck as fadCheck,
  faWebcam as fadWebcam,
  faWebcamSlash as fadWebcamSlash,
  faVolumeUp as fadVolumeUp,
  faVolumeMute as fadVolumeMute,
  faListOl as fadListOl,
  faCheckSquare as fadCheckSquare,
  faPlayCircle as fadPlayCircle,
  faStopCircle as fadStopCircle,
} from '@fortawesome/pro-duotone-svg-icons';

import {
  faCaretDown as fassCaretDown,
  faStar as fassStar,
} from '@fortawesome/sharp-solid-svg-icons';

// Solid
library.add(
  fasUsersGear,
  faSpinner,
  fasXmark,
  fadHeadphonesSimple,
  fasCloudArrowUp,
  farCloudArrowUp,
  farArrowRotateLeft,
  fadAperture,
  fasDownLeftAndUpRightToCenter,
  fasUpRightAndDownLeftFromCenter,
  fasEllipsisVertical,
  fasLink,
  fasCheckCircle,
  fasExclamationCircle,
  fasQuestionCircle,
  fasChevronDown,
  fasChevronRight,
  fasFolderOpen,
  fasBriefcase,
  fasPlay,
  fasEyeSlash,
  fasInfoCircle,
  fasChevronLeft,
  fasChevronUp,
  fasExclamationTriangle,
  fasCircle,
  fasKey,
  fasFolderTimes,
  fasGrid2,
  fasMagnifyingGlass,
  fasArrowSquareRight,
  fasClipboardListCheck,
  fasHouse,
  fasCalendarDays,
  fasChartBar,
  fasChartColumn,
  fasBell,
  fasSpinnerThird,
  fasGear,
  fasClock,
  fasMinusCircle,
  fasPlusCircle,
  fasEnvelope,
  fasComment,
  fasPlus,
  fasFolder,
  fasBadgeCheck,
  fasTimesCircle,
  fasCheck,
  fasPlusSquare,
  fasMinusSquare,
  fasCalendar,
  fasCheckSquare,
  fasTrash,
  fasArrowLeft,
  fasCog,
  fasPencil,
  fasTags,
  fasDownload,
  fasPenSquare,
  fasDoNotEnter,
  fasThList,
  fasStar,
  fasListAlt,
  fasArrowsV,
  fasUser,
  fasFileCheck,
  fasRetweet,
  fasWrench,
  fasFileExport,
  fasFilter,
  fasShare,
  fasSearch,
  fasGlobe,
  fasGlobePointer,
  fasList,
  fasTasks,
  fasRedoAlt,
  fasDesktop,
  fasFolderTree,
  fasFileAlt,
  fasChevronDoubleRight,
  fasEye,
  fasLongArrowRight,
  fasUsersClass,
  fasFilm,
  fasClone,
  fasCalendarAlt,
  fasEdit,
  fasExclamationSquare,
  fasExternalLink,
  fasTimes,
  fasLock,
  fasUserCheck,
  fasArrowUp,
  fasArrowDown,
  fasCopy,
  fasUsers,
  fasLongArrowLeft,
  fasInfo,
  fasUserLock,
  fasShieldCheck,
  fasUpload,
  fasAlarmClock,
  fasFileDownload,
  fasClosedCaptioning,
  fasTag,
  fasCaretDown,
  fasSync,
  fasClipboardCheck,
  fasLayerPlus,
  fasThLarge,
  fasMinus,
  fasHeadphones,
  fasHomeAlt,
  fasPhone,
  fasPowerOff,
  fasWebcam,
  fasMicrophone,
  fasWebcamSlash,
  fasVolumeUp,
  fasVolumeMute,
  fasPhoneAlt,
  fasFile,
  fasChevronDoubleLeft,
  fasExpand,
  fasCompress,
  fasMicrophoneSlash,
  fasVolume,
  fasVolumeSlash,
  fasExpandAlt,
  fasCompressAlt,
  fasVideo,
  fasPhonePlus,
  fasCaretUp,
  fasVideoPlus,
  fasPhotoVideo,
  fasCogs,
  fasUsersCog,
  fasQuestion,
  fasEllipsisH,
  fasScrubber,
  fasBringFront,
  fasListOl,
  fasCalendarStar,
  fasEnvelopeOpenText,
  fasArchive,
  fasHeadSideBrain,
  fasPaperclip,
  fasPrint,
  fasComments,
  fasShareSquare,
  fasMapMarker,
  fasSignIn,
  fasUserEdit,
  fasBan,
  fasArrowCircleLeft,
  fasSquare,
  fasBarChart,
  fasFilePdf,
  fasFileSpreadsheet,
  fasFileCsv,
  fasUserHeadset,
  fasServer,
  fasWifi,
  fasPhoneLaptop,
  fasInfoSquare,
  fasClipboardList,
  fasArrowRight,
  fasCloudDownloadAlt,
  fasBarcodeScan,
  fasKeyboard,
  fasPlayCircle,
  fasStopCircle,
  fasMask,
  fasW,
  fasBolt,
  fasLayerGroup,
  fasDiamond,
  fasSparkles,
  fasIDCard,
  fasCalendarDay,
  fasLanguage,
  fasLaptopMobile,
  fasLocationDot,
  fasStop,
);

// Regular
library.add(
  farExclamationTriangle,
  farArrowSquareRight,
  farBadgeCheck,
  farStar,
  farGlobe,
  farRedoAlt,
  farInfoCircle,
  farExclamationCircle,
  farUsersClass,
  farCalendarAlt,
  farFolder,
  farFolderTree,
  farTimes,
  farCog,
  farCopy,
  farPlusCircle,
  farMinusCircle,
  farClock,
  farExternalLink,
  farAlarmClock,
  farCheck,
  farTrash,
  farLayerPlus,
  farBell,
  farPlus,
  farWebcam,
  farMicrophone,
  farRetweet,
  farMicrophoneSlash,
  farPhonePlus,
  farWebcamSlash,
  farVideoPlus,
  farVideo,
  farPhotoVideo,
  farCogs,
  farUsersCog,
  farQuestion,
  farEllipsisH,
  farLongArrowLeft,
  farLongArrowRight,
  farArrowCircleLeft,
  farList,
  farUserHeadset,
  farShieldCheck,
  farCalendarDays,
  farUserEdit,
  farClipboardList,
  farEye,
  farEyeSlash
);

// Light
library.add(
  falClipboardListCheck,
  falCalendar,
  falFileCheck,
  falChevronDoubleRight,
  falInfoCircle,
  falLock,
  falUserCheck,
  falQuestionCircle,
  falTimes,
  falExclamationTriangle,
  falMicrophone,
  falWebcam,
  falServer,
  falWifi,
  falPhoneLaptop,
  falUserHeadset,
  falCheckSquare,
  falInfoSquare,
  falClipboardList,
  falVolumeUp,
  falCloudDownloadAlt,
  falBarcodeScan,
  falKeyboard,
  falClock,
  falTimesCircle,
  falUsers,
  falRectangleList,
  falFlag,
  falHeadphones,
);

// Duotone
library.add(
  fadInfoCircle,
  fadCheck,
  fadWebcam,
  fadWebcamSlash,
  fadVolumeUp,
  fadVolumeMute,
  fadListOl,
  fadCheckSquare,
  fadPlayCircle,
  fadStopCircle,
);

// Sharp Solid
library.add(
  fassCaretDown,
  fassStar,
);

dom.watch();
