import CommonDropdownMenuController from 'components/common/dropdown/menu_controller';
import { Controller } from '@hotwired/stimulus';

export default class CommonDropdownMiniController extends Controller {
  static outlets = ['common--dropdown--menu'];

  closeDropdownMenu() {
    if (this.hasCommonDropdownMenuOutlet) {
      this.commonDropdownMenuOutlets.forEach((el) => {
        el.close();
      });
    }
  }

  // common/menu/dropdown
  declare hasCommonDropdownMenuOutlet: boolean;
  declare commonDropdownMenuOutlets: CommonDropdownMenuController[];
}
