import { Controller } from '@hotwired/stimulus';

export default class UtilsRevealContentController extends Controller {
  static targets = ['item'];
  static classes = ['hidden', 'visible'];
  static values = { animate: Boolean };

  connect(): void {
    this.hiddenClassNames = this.hasHiddenClass ? this.hiddenClasses : ['hidden'];
    this.visibleClassNames = this.hasVisibleClass ? this.visibleClasses : [];
  }

  toggle(): void {
    /* if itemTarget.classList contains any hidden classes */
    if (
      [...this.hiddenClassNames].some((classname) => this.itemTarget.classList.contains(classname))
    ) {
      this.show();
    } else {
      this.hide();
    }
  }

  show(): void {
    [...this.hiddenClassNames].map((classname) => this.itemTarget.classList.remove(classname));
    [...this.visibleClassNames].map((classname) => this.itemTarget.classList.add(classname));
  }

  hide(): void {
    [...this.visibleClassNames].map((classname) => this.itemTarget.classList.remove(classname));
    [...this.hiddenClassNames].map((classname) => this.itemTarget.classList.add(classname));
  }

  declare hasHiddenClass: boolean;
  declare hasVisibleClass: boolean;
  declare hiddenClasses: string[];
  declare visibleClasses: string[];
  declare hiddenClassNames: string[];
  declare visibleClassNames: string[];
  declare animateValue: boolean;
  declare itemTarget: HTMLElement;
}
