import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        min: Number,
        max: Number,
        prefix: String,
        suffix: String,
        current: Number,
        currentSub: Number,
    };

    static targets = ['label', 'progressBar', 'currentBar', 'currentSubBar', 'currentRemainBar'];

    currentSubValueChanged() {
        this.updateProgressBar();
    }

    currentValueChanged() {
        this.updateProgressBar();
    }

    updateProgressBar() {
        this.currentBarTarget.style.width = `${this.calculatedValue()}%`;
        this.currentSubBarTarget.style.width = `${this.calculatedSubValue()}%`;
        this.currentRemainBarTarget.style.width = `${100 - (this.calculatedValue() + this.calculatedSubValue())}%`;
        this.labelTarget.innerHTML = this.valueString();
    }

    valueString() {
        return `${this.prefixValue}${this.calculatedValue()}${this.suffixValue}`;
    }

    calculatedValue() {
        return Math.floor((this.currentValue / (this.maxValue - this.minValue)) * 100);
    }

    calculatedSubValue() {
        return Math.floor((this.currentSubValue / (this.maxValue - this.minValue)) * 100);
    }
}
