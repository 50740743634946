import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["buttonGroup"];

  handleClick() {
    Array.from(this.buttonGroupTarget.children).forEach((child) => {
      this.unselect(child);
    });
    this.select(this.element);    
  }

  unselect(element) {
    element.setAttribute('aria-selected', 'false')
    element.removeAttribute('disabled');
    element.removeAttribute('tabindex')
  }

  select(element) {
    element.setAttribute('aria-selected', 'true')
    element.setAttribute('disabled', 'true')
    element.setAttribute('tabindex', '-1')
  }

  declare readonly buttonGroupTarget: HTMLElement;
}