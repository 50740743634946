import { Controller } from '@hotwired/stimulus';
import NavbarController from './navbar_controller';

export default class NavbarButtonController extends Controller {
  static outlets = ['navigation--navbar--navbar'];

  static targets = ['anchor'];

  static classes = ['active'];

  static values = { section: String };

  // use a query scope to target the correct anchor
  #dropdownMenuAnchorQuery = ':scope > .common--dropdown--menu > a';

  navigationNavbarNavbarOutletConnected() {
    this.updateActiveClasses();
  }

  updateActiveClasses() {
    if (this.navigationNavbarNavbarOutlet.activeSectionValue === this.sectionValue) {
      this.addActiveClasses();
    } else {
      this.removeActiveClasses();
    }
  }

  addActiveClasses() {
    // Handle non-dropdown buttons
    if (this.hasAnchorTarget) {
      this.anchorTarget.classList.add(...this.activeClasses);
    }

    // Handle dropdown buttons
    const dropdownMenuAnchor = this.element.querySelector(this.#dropdownMenuAnchorQuery);
    if (dropdownMenuAnchor) {
      dropdownMenuAnchor.classList.add(...this.activeClasses);
    }
  }

  removeActiveClasses() {
    // Handle non-dropdown buttons
    if (this.hasAnchorTarget) {
      this.anchorTarget.classList.remove(...this.activeClasses);
    }

    // Handle dropdown buttons
    const dropdownMenuAnchor = this.element.querySelector(this.#dropdownMenuAnchorQuery);
    if (dropdownMenuAnchor) {
      dropdownMenuAnchor.classList.remove(...this.activeClasses);
    }
  }

  declare readonly navigationNavbarNavbarOutlet: NavbarController;
  declare readonly hasAnchorTarget: boolean;
  declare readonly anchorTarget: HTMLAnchorElement;
  declare readonly activeClasses: string;
  declare sectionValue: string;
}
