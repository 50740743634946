import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'select',
    'selectableHeader',
    'selectionHeader',
    'selectableFooter',
    'selectionFooter',
    'fieldClass',
  ];

  static values = {
    filterType: String,
    inlineResults: Boolean,
  };

  connect() {
    if(this.filterTypeValue == 'radio') {
      this.initializeRadioSelect();
    } 
    else if (this.filterTypeValue == 'dropdown') {
      this.initializeDropdownSelect();
    }
    else {
      // Unless the data-single attribute is set to true, initialize the multi-select
      // otherwise it will use a regular select box
      if (this.element.dataset.single !== 'true') { this.initializeMultiSelect(); }
    }

    $.onmount();
  }

  // There is a problem with the click event when the dropdown is inline display, is open and the user clicks on the button
  // because the blur causes the results disappears before the click event is handled making the button click to not be registered.
  // This method is a workaround to handle this case as the mousedown event happens before the blur event.
  updateFilterMousedown() {
    if($(document.activeElement).closest('.ts--inline-results').length > 0 || $(document.activeElement).hasClass('filtering-option')) {
      this.updateFilter();
    };
  }

  removeFilterMousedown() {        
    if($(document.activeElement).closest('.ts--inline-results').length > 0 || $(document.activeElement).hasClass('filtering-option')) {
      this.removeFilter();
    };
  }

  hidePopup() {
    $(this.element).closest('.qtip').qtip('hide');
  }

  updateFilter() {
    this.hidePopup();

    window.updateFilterBy($(this.element));
    window.sortByUpdate($('.list-filtering'));
  }

  removeFilter() {
    this.hidePopup();

    const filteringContainer = $('.list-filtering');
    const filterByFieldClass = $(this.fieldClassTarget).val();
    const filterByInput = $(filteringContainer.find(`.${filterByFieldClass}`));

    const filterByInputInclusive = $(filteringContainer.find(`.${filterByFieldClass}-inclusive`));

    filterByInputInclusive.val('true');
    filterByInput.val('none');

    window.sortByUpdate($('.list-filtering'));
  }

  resetState() {
    if(this.filterTypeValue == 'radio') {
      $(this.element).find("input[type='radio']").each(function(index, radio) {
        $(radio).prop('checked', ($(radio).data('initial-value').toString() == "true"));
      });
    } 
    else if (this.filterTypeValue == 'dropdown') {
      AdvancedSelect.helper.setValue($(this.element).find(".filter-by-select"), $(this.element).data("initial-filtered-value"));
    }
    else {
      // Currently no need to reset the default multi-select.
    }
  }

  selectAll() {
    $(this.selectTarget).multiSelect('select_all');
  }

  deselectAll() {
    $(this.selectTarget).multiSelect('deselect_all');
  }

  initializeRadioSelect = () => {
    $(this.element).find("input[type='radio']").on('change', (e) => {
      this.updateFilter();
    });
  }

  initializeDropdownSelect = () => {
    
  }

  initializeMultiSelect = () => {    
    const options = {
      selectableHeader: this.selectableHeaderTarget.content,
      selectionHeader: this.selectionHeaderTarget.content,
      selectableFooter: this.selectableFooterTarget.content,
      selectionFooter: this.selectionFooterTarget.content,
    };

    if (this.element.dataset.use_search === 'true') {
      options.afterInit = function () { // eslint-disable-line
        const multiSelect = this;

        const selectableSearch = multiSelect.$selectableUl.prev().find('input[type="text"]');
        const selectionSearch = multiSelect.$selectionUl.prev().find('input[type="text"]');
        const selectableSearchString = `#${multiSelect.$container.attr('id')} .ms-elem-selectable:not(.ms-selected)`;
        const selectionSearchString = `#${multiSelect.$container.attr('id')} .ms-elem-selection.ms-selected`;

        multiSelect.qs1 = selectableSearch.quicksearch(selectableSearchString).on('keydown', function (e) { // eslint-disable-line
          if (e.which === 40) {
            multiSelect.$selectableUl.trigger('focus');
            return false;
          }
        });

        multiSelect.qs2 = selectionSearch.quicksearch(selectionSearchString).on('keydown', function (e) { // eslint-disable-line
          if (e.which === 40) {
            multiSelect.$selectionUl.trigger('focus');
            return false;
          }
        });
      };

      options.afterSelect = function () { // eslint-disable-line
        this.qs1.cache();
        this.qs2.cache();
      };

      options.afterDeselect = function () { // eslint-disable-line
        this.qs1.cache();
        this.qs2.cache();
      };
    }

    $(this.selectTarget).multiSelect(options);
  };
}
