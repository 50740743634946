import UtilsRevealContentController from 'components/utils/reveal/content_controller';
import { Controller } from '@hotwired/stimulus';

export default class UtilsRevealButtonController extends Controller {
  static outlets = ['utils--reveal--content'];

  outletName: string | null | undefined;

  connect(): void {
    this.outletName = this.element.getAttribute(
      'data-utils--reveal--button-utils--reveal--content-outlet',
    );
  }

  toggle(): void {
    if (this.hasUtilsRevealContentOutlet) {
      this.utilsRevealContentOutlets.forEach((outlet) => {
        outlet.toggle();
      });
    } else {
      console.error(`Missing outlet "${this.outletName}"`);
    }
  }

  show(): void {
    if (this.hasUtilsRevealContentOutlet) {
      this.utilsRevealContentOutlets.forEach((outlet) => {
        outlet.show();
      });
    } else {
      console.error(`Missing outlet "${this.outletName}"`);
    }
  }

  hide(): void {
    if (this.hasUtilsRevealContentOutlet) {
      this.utilsRevealContentOutlets.forEach((outlet) => {
        outlet.hide();
      });
    } else {
      console.error(`Missing outlet "${this.outletName}"`);
    }
  }

  declare hasUtilsRevealContentOutlet: boolean;
  declare utilsRevealContentOutlets: UtilsRevealContentController[];
}
