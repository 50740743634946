import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['counter'];

  inputElement: HTMLInputElement | null | undefined = undefined;

  initialize(): void {
    this.update = this.update.bind(this);
  }

  connect(): void {
    const inputElQuery = this.element.getAttribute('data-input-element');

    if (!inputElQuery) {
      console.error('No `data-input-element` attribute.');
      return;
    }

    this.inputElement = document.querySelector(inputElQuery) as HTMLInputElement | null;

    if (!this.inputElement) {
      console.error('No input element found!');
      return;
    }

    this.update();
    this.inputElement.addEventListener('input', this.update);
  }

  disconnect(): void {
    if (!this.inputElement) {
      console.error('No input element found!');
      return;
    }

    this.inputElement.removeEventListener('input', this.update);
  }

  update(): void {
    this.counterTarget.innerHTML = this.count.toString();
  }

  get count(): number {
    let value: number = this.inputElement ? this.inputElement.value.length : 0;

    return value;
  }

  declare readonly counterTarget: HTMLSpanElement;
}
