import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['conditionRow', 'conditionValueCell', 'conditionableTypeSelector',
  'operatorSelector', 'personFieldAnswerValueFieldSelector', 'targetTypeIndicators', 'answerIndicator',
  'fieldIndicator', 'staticValueIndicator', 'requistionBusinessProcessNameTypeSelector',
  'personFieldAnswerValueFieldOptionSelector','personFieldAnswerOptionOperator',
  'requistionBusinessProcessNameFieldSelector', 'requistionBusinessProcessNameStaticValueInput'];

  static outlets = ['conditional-actions--conditions'];

  static values = {
    index: Number,
    targetTypes: Object,
    optionNotAvailable: String,
  };

  connect() {
    const self = this;
    setTimeout(() => {
      this.handleSelectedConditionableType();
      self.conditionalActionsConditionsOutlet.resetDeleteOptions();
    }, 1);
  }

  handleSelectedConditionableType() {
    // Only in one case do we enable the second operator value
    if (this.conditionableTypeSelectorTarget.value != this.conditionalActionsConditionsOutlet.conditionableTypesValue.requistion_business_process_name) {
      this.operatorSelectorTarget.options[1].disabled = true;
    } else {
      this.operatorSelectorTarget.options[1].disabled = false;
    }

    // Depending on the selected type, we have different filtering options
    // requistion_business_process_name selected
    if (this.conditionableTypeSelectorTarget.value == this.conditionalActionsConditionsOutlet.conditionableTypesValue.requistion_business_process_name) {
      this.changeStateOfRequistionBusinessProcessNameFields('enable');

      this.changeStateOfRequisitionBucketIdFields('disable');
      this.changeStateOfPersonFieldAnswerValueFields('disable');

      setTimeout(() => {
        // Add the required validation for the static value inputs
        $('.condition-row .staticValueInput:not(\'.hidden\')').rules('add', 'trim');
        $('.condition-row .staticValueInput:not(\'.hidden\')').rules('add', 'required');
      }, 1);

    }

    // requisition_bucket_id selected
    if (this.conditionableTypeSelectorTarget.value == this.conditionalActionsConditionsOutlet.conditionableTypesValue.requisition_bucket_id) {
      this.changeStateOfRequisitionBucketIdFields('enable');

      this.changeStateOfRequistionBusinessProcessNameFields('disable');
      this.changeStateOfPersonFieldAnswerValueFields('disable');
    }

    // person_field_answer_value selected
    if (this.conditionableTypeSelectorTarget.value == this.conditionalActionsConditionsOutlet.conditionableTypesValue.person_field_answer_value) {
      this.changeStateOfPersonFieldAnswerValueFields('enable');

      this.changeStateOfRequistionBusinessProcessNameFields('disable');
      this.changeStateOfRequisitionBucketIdFields('disable');
      this.updatePersonFieldAnswerValueFieldOptionSelector();
    }

    setTimeout(() => {
      this.handleSelectedRequistionBusinessProcessNameType();
      this.conditionalActionsConditionsOutlet.resetConditionalOptionValues();}, 1);
  }

  // Associate the correct select element with the currently selected field
  updatePersonFieldAnswerValueFieldOptionSelector() {
    this.personFieldAnswerValueFieldOptionSelectorTarget.innerHTML = this.conditionalActionsConditionsOutlet.personFieldAnswerValueFieldOptionSelectorsValue[this.personFieldAnswerValueFieldSelectorTarget.value].replace('replace_with_index',this.indexValue);
    setTimeout(() => {
      if (this.personFieldAnswerValueFieldOptionSelectorTarget.dataset.default != '') {
        // Make sure this option exists (we may have changed to a different field)
        const optionsValues = Array.from(this.personFieldAnswerValueFieldOptionSelectorTarget.querySelector('select').options).map((opt) => opt.value);


        if (optionsValues.includes(this.personFieldAnswerValueFieldOptionSelectorTarget.dataset.default)) {
          this.personFieldAnswerValueFieldOptionSelectorTarget.querySelector('select').value = this.personFieldAnswerValueFieldOptionSelectorTarget.dataset.default;
        } else {
          // We need to add the not available option
          const option = document.createElement('option');
          option.text = this.optionNotAvailableValue;
          option.value = 0;
          this.personFieldAnswerValueFieldOptionSelectorTarget.querySelector('select').prepend(option);
          this.personFieldAnswerValueFieldOptionSelectorTarget.querySelector('select').selectedIndex = 0;
        }
      }
    }, 1);

  }

  // user can select static or dynamic target type when requistion_business_process_name
  handleSelectedRequistionBusinessProcessNameType() {

    this.hideRequistionBusinessProcessNameTargetFields();

    if (this.conditionableTypeSelectorTarget.value == this.conditionalActionsConditionsOutlet.conditionableTypesValue.requistion_business_process_name) {
      this.hideTargetTypeIndicators();
      if (this.requistionBusinessProcessNameTypeSelectorTarget.value == this.targetTypesValue.static) {
        this.staticValueIndicatorTarget.classList.remove('hidden');
        this.requistionBusinessProcessNameStaticValueInputTarget.classList.remove('hidden');
        this.requistionBusinessProcessNameStaticValueInputTarget.disabled = false;
      } else {
        this.fieldIndicatorTarget.classList.remove('hidden');
        this.requistionBusinessProcessNameFieldSelectorTarget.classList.remove('hidden');
        this.requistionBusinessProcessNameFieldSelectorTarget.disabled = false;
      }
    }
  }

  changeStateOfRequistionBusinessProcessNameFields(newState) {

    this.hideTargetTypeIndicators();

    if (newState == 'enable') {
      this.conditionValueCellTarget.querySelector('.requistion_business_process_name').classList.remove('hidden');
      Array.prototype.forEach.call(this.conditionValueCellTarget.querySelectorAll('.toggleable'), (el) => {
        el.disabled=false;
      });
      this.staticValueIndicatorTarget.classList.remove('hidden');
    } else {
      this.conditionValueCellTarget.querySelector('.requistion_business_process_name').classList.add('hidden');
      Array.prototype.forEach.call(this.conditionValueCellTarget.querySelectorAll('.requistion_business_process_name .toggleable'), (el) => {
        el.disabled=true;
      });
    }
  }

  changeStateOfRequisitionBucketIdFields(newState) {

    this.hideTargetTypeIndicators();

    if (newState == 'enable') {
      this.conditionValueCellTarget.querySelector('.requisition_bucket_id').classList.remove('hidden');
      Array.prototype.forEach.call(this.conditionValueCellTarget.querySelectorAll('.requisition_bucket_id .toggleable'), (el) => {
        el.disabled=false;
      });
    } else {
      this.conditionValueCellTarget.querySelector('.requisition_bucket_id').classList.add('hidden');
      Array.prototype.forEach.call(this.conditionValueCellTarget.querySelectorAll('.requisition_bucket_id .toggleable'), (el) => {
        el.disabled=true;
      });
    }
  }

  changeStateOfPersonFieldAnswerValueFields(newState) {

    this.hideTargetTypeIndicators();

    if (newState == 'enable') {
      this.conditionValueCellTarget.querySelector('.person_field_answer_value').classList.remove('hidden');
      Array.prototype.forEach.call(this.conditionValueCellTarget.querySelectorAll('.person_field_answer_value .toggleable'), (el) => {
        el.disabled=false;
      });
      this.personFieldAnswerOptionOperatorTarget.classList.remove('hidden');
      this.personFieldAnswerValueFieldOptionSelectorTarget.innerHTML ='';

      setTimeout(() => {
        this.answerIndicatorTarget.classList.remove('hidden');
      }, 1);

    } else {
      this.personFieldAnswerOptionOperatorTarget.classList.add('hidden');
      this.conditionValueCellTarget.querySelector('.person_field_answer_value').classList.add('hidden');
      Array.prototype.forEach.call(this.conditionValueCellTarget.querySelectorAll('.person_field_answer_value .toggleable'), (el) => {
        el.disabled=true;
      });
      this.personFieldAnswerValueFieldOptionSelectorTarget.innerHTML ='';
    }
  }

  hideTargetTypeIndicators() {
    Array.prototype.forEach.call(this.targetTypeIndicatorsTarget.querySelectorAll('div'), (el) => {
      el.classList.add('hidden');
    });
  }

  hideRequistionBusinessProcessNameTargetFields() {
    this.requistionBusinessProcessNameStaticValueInputTarget.classList.add('hidden');
    this.requistionBusinessProcessNameStaticValueInputTarget.disabled = true;
    this.requistionBusinessProcessNameFieldSelectorTarget.classList.add('hidden');
    this.requistionBusinessProcessNameFieldSelectorTarget.disabled = true;
  }

  deleteConditionRow(e) {
    if (e.currentTarget.classList.contains('disabled') === false) {
      const deletedValue = this.conditionableTypeSelectorTarget.value;
      this.conditionRowTarget.remove();
      this.conditionalActionsConditionsOutlet.resetDeleteOptions();
      this.conditionalActionsConditionsOutlet.enableConditionalActionOptionValue(deletedValue);
      this.conditionalActionsConditionsOutlet.handleConditionRowChange();
    }
  }
}
