import { Controller } from '@hotwired/stimulus';
import { useDebounce, useIntersection } from 'stimulus-use';

export default class NavigationLinkbarCompaniesLauncher extends Controller {
  static targets = ['input', 'list', 'nextPage', 'searchIcon', 'loadingIcon'];

  static debounces = ['search'];

  connect() {
    useDebounce(this, { wait: 300 });

    // Add intersection observer to input only if it exists
    if (this.hasInputTarget) {
      useIntersection(this, {
        element: this.inputTarget,
      });
    }
  }

  appear() {
    this.inputTarget.focus();
  }

  async search() {
    const terms = this.inputTarget.value.toLowerCase();

    if (terms.length === 0 || terms.length >= 3) {
      this.showLoadingIcon();
      this.disableNextPage();
      this.clearResults();
      await NavigationLinkbarCompaniesLauncher.fetchResults(terms);
      this.showSearchIcon();
    }
  }

  clearResults() {
    this.listTarget.innerHTML = '';
  }

  disableNextPage() {
    this.nextPageTarget.setAttribute('disabled', '');
  }

  enableNextPage() {
    this.nextPageTarget.removeAttribute('disabled');
  }

  showLoadingIcon() {
    this.searchIconTarget.classList.add('hidden');
    this.loadingIconTarget.classList.remove('hidden');
  }

  showSearchIcon() {
    this.loadingIconTarget.classList.add('hidden');
    this.searchIconTarget.classList.remove('hidden');
  }

  static async fetchResults(terms?: string) {
    const params = terms ? `?terms=${terms}` : '';
    await fetch(`/companies/launcher.turbo_stream${params}`, {
      headers: {
        'Content-Type': 'text/vnd.turbo-stream.html',
      },
    }).then((response) => response.text())
      .then((html) => window.Turbo.renderStreamMessage(html));
  }

  declare readonly listTarget: HTMLUListElement;
  declare readonly nextPageTarget: HTMLElement;
  declare readonly searchIconTarget: HTMLElement;
  declare readonly loadingIconTarget: HTMLElement;
  declare readonly hasInputTarget: boolean;
  declare readonly inputTarget: HTMLInputElement;
}
