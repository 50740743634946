import { Controller } from '@hotwired/stimulus';
import { useClickOutside } from 'stimulus-use';
import { useHotkeys } from 'stimulus-use/dist/hotkeys';

export default class CommonDropdownMenuController extends Controller {
  static targets = ['content', 'button'];

  connect() {
    useClickOutside(this);
    useHotkeys(this, {
      esc: [this.close],
    });
  }

  toggle() {
    if (this.buttonTarget.ariaExpanded === 'true') {
      return this.close();
    }
    return this.open();
  }

  clickOutside() {
    this.close();
  }

  open() {
    this.contentTarget.classList.remove('hidden');
    this.contentTarget.classList.remove('content-visibility-hidden');
    this.buttonTarget.ariaExpanded = 'true';
  }

  close() {
    this.contentTarget.classList.add('hidden');
    this.contentTarget.classList.add('content-visibility-hidden');
    this.buttonTarget.ariaExpanded = 'false';
  }

  declare readonly contentTarget: HTMLElement;
  declare readonly buttonTarget: HTMLAnchorElement;
}
