import { metaContent } from '../utils';
type Region = 'default' | 'US' | 'CA' | 'GC' | 'AUS' | 'EU' | 'UK';
function iso2CountryCodesListByRegion(region: Region | undefined) {
  const countryCodes: {
    default: string[],
    US: string[],
    CA: string[],
    GC: string[],
    AUS: string[],
    EU: string[],
    UK: string[]} = {
      default: [],
      US: [],
      CA: [],
      GC: [],
      AUS: [],
      EU: [],
      UK: [],
    };

  countryCodes.default = [
    'AL', // Albania
    'AD', // Andorra
    'AT', // Austria
    'BY', // Belarus
    'BE', // Belgium
    'BA', // Bosnia and Herzegovina
    'BG', // Bulgaria
    'HR', // Croatia
    'CY', // Cyprus
    'CZ', // Czech Republic
    'DK', // Denmark
    'EE', // Estonia
    'FO', // Faroe Islands
    'FI', // Finland/Aland Islands
    'FR', // France
    'DE', // Germany
    'GI', // Gibraltar
    'GR', // Greece
    'HU', // Hungary
    'IS', // Iceland
    'IE', // Ireland
    'IT', // Italy
    'XK', // Kosovo
    'LV', // Latvia
    'LI', // Liechtenstein
    'LT', // Lithuania
    'LU', // Luxembourg
    'MT', // Malta
    'MD', // Moldova
    'MC', // Monaco
    'ME', // Montenegro
    'NL', // Netherlands
    'NO', // Norway
    'PH', // Philippines,
    'PL', // Poland
    'PT', // Portugal
    'MK', // Republic of North Macedonia
    'RO', // Romania
    'SM', // San Marino
    'RS', // Serbia
    'SK', // Slovakia
    'SI', // Slovenia
    'ES', // Spain
    'SE', // Sweden
    'CH', // Switzerland
    'UA', // Ukraine
    'GB', // United Kingdom
    'VA', // Vatican City
    'US', // United States
    'CA', // Canada
    'TT', // Trinidad and Tobago
    'AU', // Australia
    'CC', // Australia/Cocos/Christmas Island
    'NZ', // New Zealand
  ];

  countryCodes.US = [
    'US', // United States,
    'CA', // Canada,
    'IE', // Ireland
  ];

  // CORE-4248 - makes it easier for testing in sandbox by enabling AU SMS prefix
  if (metaContent('env') === 'sandbox' || metaContent('env') === 'development') {
    countryCodes.US.push('AU');
  }

  countryCodes.CA = [
    'US', // United States,
    'CA', // Canada,
    'IE', // Ireland,
    'TT', // Trinidad and Tobago
    'GB', // United Kingdom
  ];

  countryCodes.GC = [
    'US', // United States,
    'CA', // Canada,
    'PH', // Philippines,
    'IE', // Ireland,
    'TT', // Trinidad and Tobago
    'GB', // United Kingdom
  ];

  countryCodes.AUS = [
    'AU', // Australia
    'CA', // Canada
    'CC', // Australia/Cocos/Christmas Island
    'CN', // China
    'HK', // Hong Kong
    'NZ', // New Zealand
    'SG', // Singapore
    'US', // United States
  ];

  countryCodes.EU = [
    'AL', // Albania
    'AD', // Andorra
    'AT', // Austria
    'BY', // Belarus
    'BE', // Belgium
    'BA', // Bosnia and Herzegovina
    'BG', // Bulgaria
    'HR', // Croatia
    'CY', // Cyprus
    'CZ', // Czech Republic
    'DK', // Denmark
    'EE', // Estonia
    'FO', // Faroe Islands
    'FI', // Finland/Aland Islands
    'FR', // France
    'DE', // Germany
    'GI', // Gibraltar
    'GR', // Greece
    'HU', // Hungary
    'IS', // Iceland
    'IE', // Ireland
    'IT', // Italy
    'XK', // Kosovo
    'LV', // Latvia
    'LI', // Liechtenstein
    'LT', // Lithuania
    'LU', // Luxembourg
    'MT', // Malta
    'MD', // Moldova
    'MC', // Monaco
    'ME', // Montenegro
    'NL', // Netherlands
    'NO', // Norway
    'PL', // Poland
    'PT', // Portugal
    'MK', // Republic of North Macedonia
    'RO', // Romania
    'SM', // San Marino
    'RS', // Serbia
    'SK', // Slovakia
    'SI', // Slovenia
    'ES', // Spain
    'SE', // Sweden
    'CH', // Switzerland
    'UA', // Ukraine
    'GB', // United Kingdom
    'VA', // Vatican City
  ];

  countryCodes.UK = [
    'AL', // Albania
    'AD', // Andorra
    'AT', // Austria
    'BY', // Belarus
    'BE', // Belgium
    'BA', // Bosnia and Herzegovina
    'BG', // Bulgaria
    'CA', // Canada
    'HR', // Croatia
    'CY', // Cyprus
    'CZ', // Czech Republic
    'DK', // Denmark
    'EE', // Estonia
    'FO', // Faroe Islands
    'FI', // Finland/Aland Islands
    'FR', // France
    'DE', // Germany
    'GI', // Gibraltar
    'GR', // Greece
    'HU', // Hungary
    'IS', // Iceland
    'IE', // Ireland
    'IT', // Italy
    'XK', // Kosovo
    'LV', // Latvia
    'LI', // Liechtenstein
    'LT', // Lithuania
    'LU', // Luxembourg
    'MT', // Malta
    'MD', // Moldova
    'MC', // Monaco
    'ME', // Montenegro
    'NL', // Netherlands
    'NO', // Norway
    'PL', // Poland
    'PT', // Portugal
    'MK', // Republic of North Macedonia
    'RO', // Romania
    'SM', // San Marino
    'RS', // Serbia
    'SK', // Slovakia
    'SI', // Slovenia
    'ES', // Spain
    'SE', // Sweden
    'CH', // Switzerland
    'UA', // Ukraine
    'GB', // United Kingdom
    'US', // United States
    'VA', // Vatican City
  ];

  if (region) {
    return countryCodes[region];
  }
  return countryCodes.default;
}
window.iso2CountryCodesListByRegion = iso2CountryCodesListByRegion;

export default iso2CountryCodesListByRegion;
export { Region };
