import '@hotwired/turbo-rails';

/**
 * TEMPORARY FIX: We have to listen for when Turbo fails to load a page and force a full page reload to
 * prevent "content missing" errors when the application shows an error page. This was the default behaviour
 * in Turbo 7, but changed in Turbo 8. In Rails 7, the proper <meta> tags are added to the default Rails
 * error page layouts to force full page reloads so we will no longer need this when we upgrade.
 */
document.addEventListener('turbo:frame-missing', (event) => {
  const {
    detail: { response, visit },
  } = event;
  event.preventDefault();
  visit(response.url);
});

/**
 * Note: Turbo.StreamActions must use a regular function (non-arrow) to maintain the scope of `this`
 */

/**
 * Add a toast (meow) notification in a Turbo stream response.
 * @example
 * <%= turbo_stream.toast(
 *  "title",
 *  "message",
 *  image_path("loader_squares.gif"),
 *  10000,
 *  @notification.unique_identifier)
 * %>
 */
window.Turbo.StreamActions.toast = function _() {
  const title = this.getAttribute('title');
  const message = this.getAttribute('message');
  const icon = this.getAttribute('icon');
  const duration = this.getAttribute('duration');
  const classes = this.getAttribute('classes');

  $.meow({
    title,
    message,
    icon,
    duration,
    classes,
  });
};

/**
 * Remove a toast (meow) notification in a Turbo stream response.
 * This can be useful for clearing out Infinity duration toasts.
 * @example
 * <%= turbo_stream.toast_remove @notification.unique_identifier %>
 */
window.Turbo.StreamActions.toast_remove = function _() {
  const id = this.getAttribute('id') || this.getAttribute('target');

  // Find the meow
  // Make sure to escape the id as it could start with a number
  const toast = document.querySelector(`.meow.${CSS.escape(id)}`);

  if (toast) {
    // Add fade out
    toast.classList.add('transition-opacity', 'opacity-0', 'duration-300');

    // Remove the element when animation complete
    setTimeout(() => {
      toast.remove();
    }, 300);
  }
};
