import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['progressCircle', 'label', 'containerSvg', 'backgroundCircle'];

  static values = {
    current: Number,
    width: Number,
    radius: Number,
    stroke: Number,
    normalizedRadius: Number,
    calculatedCircumference: Number,
    labelOverride: String,
    backgroundColor: String,
    progressBarColor: String,
    hideLabel: Boolean,
    spin: Boolean
  };

  connect() {
  }

  refreshLabel() {
    let percent = this.currentValue;

    if (percent > 100) {
      percent = 100;
    }

    if(this.hideLabelValue) {
      this.labelTarget.innerText = '';
    } else {
      if(this.labelOverrideValue === '') {
        this.labelTarget.innerText = `${Math.round(percent)}%`;
      }
      else {
        this.labelTarget.innerText = this.labelOverrideValue;
      }
    }
  }

  backgroundColorValueChanged(current, old) {
    if(old) {
      this.backgroundCircleTarget.classList.remove(old);
    }
    this.backgroundCircleTarget.classList.add(current);
  }

  progressBarColorValueChanged(current, old) {
    $(this.containerSvgTarget).attr("stroke", current);
  }

  spinValueChanged() {
    if(this.spinValue) {
      $(this.element).css("animation-iteration-count", "infinite");
      $(this.element).css("animation-duration", "2s");
      this.element.style.animationName = "radial-progress-spin";      
    } else {  
      $(this.element).css("animation-duration", null);
      this.element.style.animationName = "";
    }
  }

  currentValueChanged() {
    let percent = this.currentValue;

    if (percent > 100) {
      percent = 100;
    }
  
    // Set the aria role value for screen readers
    this.element.setAttribute("aria-valuenow", percent);
    
      // Calculate a dash offset value based on the calculated circumference and the current percentage
      this.progressCircleTarget.style.strokeDashoffset =
        this.calculatedCircumferenceValue -
        (percent / 100) * this.calculatedCircumferenceValue;
  
      // A human readable version for the text label
      this.refreshLabel();

      // Set a complete or pending state based on progress
      if (percent == 100) {
        this.element.setAttribute("data-progress-state", "complete");
      } else {
        this.element.setAttribute("data-progress-state", "pending");
      }
  }

  labelOverrideValueChanged() {
    this.refreshLabel();
  }
}
