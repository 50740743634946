import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['questionRow'];

  static outlets = ['scoring-fields--questions'];

  connect() {
    const self = this;
    setTimeout(() => {
      self.scoringFieldsQuestionsOutlet.resetDeleteOptions();
    }, 1);
  }

  deleteQuestion(e) {
    if (e.currentTarget.classList.contains('disabled') === false) {
      this.questionRowTarget.remove();
      this.scoringFieldsQuestionsOutlet.resetDeleteOptions();
    }
  }
}
