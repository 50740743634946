import { Controller } from '@hotwired/stimulus';

export default class PasswordFieldController extends Controller {
  static targets = ['input', 'showButton', 'icon'];

  toggle() {
    /*
     * Update the data-icon attribute, not the element class, to prevent FA from re-hydrating
     * the icon causing a bug that sets the data-prefix back to solid. FA will update the element
     * classList for us automatically when setting the data-icon anyways.
     */
    if (this.inputTarget.type === 'password') {
      this.inputTarget.type = 'text';
      this.iconTarget.setAttribute('data-icon', 'eye-slash');
      this.showButtonTarget.setAttribute('aria-pressed', 'true');
    } else {
      this.inputTarget.type = 'password';
      this.iconTarget.setAttribute('data-icon', 'eye');
      this.showButtonTarget.setAttribute('aria-pressed', 'false');
    }
  }

  declare readonly inputTarget: HTMLInputElement;
  declare readonly showButtonTarget: HTMLElement;
  declare readonly iconTarget: HTMLElement;
}
