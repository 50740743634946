import { Controller } from '@hotwired/stimulus';

export default class CommonClipboardButtonController extends Controller {
  static targets = ['button', 'text'];
  static values = { copy: String, success: String };

  originalContent: string = '';

  connect() {
    this.originalContent = this.buttonTarget.innerHTML;
  }

  async copy(e: Event) {
    e.preventDefault();

    if (!window.isSecureContext) {
      console.error(
        'Copying to clipboard can only be done in a secure context. See: https://developer.mozilla.org/en-US/docs/Web/API/Clipboard/writeText',
      );
      return;
    }

    try {
      await window.navigator.clipboard.writeText(this.copyValue).then(() => this.copied());
    } catch (error) {
      console.error(error.message);
    }
  }

  copied() {
    this.textTarget.innerHTML = this.successValue;

    setTimeout(() => {
      this.buttonTarget.innerHTML = this.originalContent;
    }, 1000);
  }

  declare readonly buttonTarget: HTMLButtonElement;
  declare readonly textTarget: HTMLSpanElement;
  declare copyValue: string;
  declare successValue: string;
}
