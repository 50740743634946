import { Controller } from '@hotwired/stimulus';

type UrlParams = {
  model?: string;
  date?: string;
  person_id?: string;
  business_process_id?: string;
  user_id?: string;
}

type Filters = {
  event: string | null,
  date: string | null,
  position: string | null,
  user: string | null,
}

export default class HistoryTimelineController extends Controller {
  static targets = ['spinner', 'currentActivities', 'nextActivities'];

  static values = {
    personId: Number,
    ajaxUrl: String,
  };

  refresh(filters: Filters) {
    const params: UrlParams = {};

    if (filters.event && filters.event !== '') {
      params.model = filters.event;
    }

    if (filters.date && filters.date !== '') {
      params.date = filters.date;
    }

    if (filters.position && filters.position !== '') {
      params.business_process_id = filters.position;
    }

    if (filters.user && filters.user !== '') {
      params.user_id = filters.user;
    }

    if (this.personIdValue) {
      params.person_id = this.personIdValue.toString();
    }

    const urlParams = new URLSearchParams(params).toString();

    // clear current activities
    this.currentActivitiesTarget.innerHTML = '';

    // show loading spinner
    this.spinnerTarget.classList.remove('hidden');

    // reset nextActivities turbo frame src and it will fetch new activities automatically
    this.nextActivitiesTarget.setAttribute('src', `${this.ajaxUrlValue}?page=1&${urlParams}`);
  }

  declare readonly spinnerTarget: HTMLElement;
  declare readonly currentActivitiesTarget: HTMLElement;
  declare readonly nextActivitiesTarget: HTMLElement; // Turbo.FrameElement
  declare personIdValue: number;
  declare ajaxUrlValue: string;
}
