import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { id: String, current: String };

  currentValueChanged() {
    // Update the currently active step.
    $(this.element).find("[data-common--progress--step-id-value]").attr('data-common--progress--step-is-current-value', false);
    $(this.element).find("[data-common--progress--step-id-value='" + this.currentValue + "']").attr('data-common--progress--step-is-current-value', true);    
  }

  completeStep(stepIdentifier) {
    $(this.element).find("[data-common--progress--step-id-value='" + stepIdentifier + "']").attr('data-common--progress--step-is-complete-value', true);
  }
}
