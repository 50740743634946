import { Controller } from '@hotwired/stimulus';
import { useResize } from 'stimulus-use';
import { useHotkeys } from 'stimulus-use/hotkeys';
import CommonTabsTabController from './tab_controller';

export default class CommonTabsTabsController extends Controller {
  static outlets = ['common--tabs--tab'];

  static targets = ['nav', 'leftButton', 'rightButton'];

  static values = { tabScrollIndex: Number, scrollIntoView: Boolean };

  // The state of if the component should have arrow buttons or not
  hasArrowButtons: boolean = false;

  // The maximum tab index that can be scrolled to.
  // This prevents an issue where it can take multiple clicks to scroll.
  maxTabIndex: number = 0;

  // The currently focused tab
  tabFocusIndex: number = 0;

  // Lifecycle methods

  initialize() {
    // set maxTabIndex
    this.maxTabIndex = this.calculateMaxTabIndex();

    // set focused tab index state to the currently active tab
    this.tabFocusIndex = this.activeTabIndex();

    // enable handleShadows to access `this` from event listener
    this.handleShadows = this.handleShadows.bind(this);
  }

  connect() {
    // set up accessible hotkeys
    useHotkeys(this, {
      left: [this.handleLeftArrowKeyPress, this.element as HTMLElement],
      right: [this.handleRightArrowKeyPress, this.element as HTMLElement],
      space: [this.handleSpaceKeyPress, this.element as HTMLElement],
      home: [this.handleHomeKeyPress, this.element as HTMLElement],
      end: [this.handleEndKeyPress, this.element as HTMLElement],
      },
    );

    // reinitialize everything if the browser size changes to recalculate responsive behaviour
    useResize(this);

    // enable or disable arrow buttons
    this.handleArrowButtons();

    // redraw shadows whenever the nav scrolls
    this.navTarget.addEventListener('scroll', this.handleShadows);

    // instantly scroll active tab into view
    if(this.scrollIntoViewValue){
      this.commonTabsTabOutletElements[this.tabScrollIndexValue].scrollIntoView({
        behavior: 'instant',
        block: 'nearest',
        inline: 'start',
      });
    }
  }

  disconnect() {
    this.navTarget.removeEventListener('scroll', this.handleShadows);
  }

  resize() {
    this.handleArrowButtons();
    this.handleShadows();
  }

  tabScrollIndexValueChanged() {
    // debug which element is "focused"
    // this.commonTabsTabOutletElements.forEach((el) => el.classList.remove('bg-neutral-100'));
    // this.commonTabsTabOutletElements[this.tabScrollIndexValue].classList.add('bg-neutral-100');

    // prevent scrolling to tabs that are outside the max
    if (this.tabScrollIndexValue > this.maxTabIndex) {
      this.tabScrollIndexValue = this.maxTabIndex;
    }

    // scroll tab into view
    if(this.scrollIntoViewValue){
      this.commonTabsTabOutletElements[this.tabScrollIndexValue].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }

  // Actions

  deselectAllTabs() {
    this.commonTabsTabOutlets.forEach((tab) => tab.deselect());
  }

  scrollRight() {
    if (this.tabScrollIndexValue < this.maxTabIndex) {
      this.tabScrollIndexValue += 1;
    }
  }

  scrollLeft() {
    if (this.tabScrollIndexValue > 0) {
      this.tabScrollIndexValue -= 1;
    }
  }

  // Helpers

  calculateMaxTabIndex() {
    let maxTabIndex = this.commonTabsTabOutletElements.length;
    const containerWidth = this.navTarget.offsetWidth;
    let tabWidth = this.commonTabsTabOutletElements[maxTabIndex - 1].offsetWidth;

    // NOTE: This should be: `this.leftButtonTarget.offsetWidth + this.rightButtonTarget.offsetWidth`,
    // but the buttons haven't initialized yet so it needs to be hardcoded.
    const buttonsWidth = 68 + 32 + 4; // buttons + margins + slop?

    while (tabWidth <= containerWidth - buttonsWidth) {
      if (maxTabIndex <= 1) {
        break;
      }
      maxTabIndex -= 1;
      tabWidth += this.commonTabsTabOutletElements[maxTabIndex - 1].offsetWidth;
    }

    return maxTabIndex;
  }

  handleArrowButtons() {
    // if hasArrowButtons value is different
    if (this.hasArrowButtons !== this.isOverflowing()) {
      // show arrows if content overflows
      if (this.isOverflowing()) {
        this.hasArrowButtons = true;
        this.showArrowButtons();
      } else {
        this.hasArrowButtons = false;
        this.hideArrowButtons();
      }
    }
  }

  showArrowButtons() {
    this.leftButtonTarget.classList.remove('hidden');
    this.rightButtonTarget.classList.remove('hidden');
  }

  hideArrowButtons() {
    this.leftButtonTarget.classList.add('hidden');
    this.rightButtonTarget.classList.add('hidden');
  }

  handleShadows() {
    if (this.hasArrowButtons) {
      // left shadows
      if (this.navTarget.scrollLeft) {
        this.leftButtonTarget.classList.add('shadow-wide');
      } else {
        this.leftButtonTarget.classList.remove('shadow-wide');
      }
      // right shadows
      if (
        this.navTarget.scrollWidth - this.navTarget.scrollLeft - this.navTarget.offsetWidth <= 1
      ) {
        this.rightButtonTarget.classList.remove('shadow-wide');
      } else {
        this.rightButtonTarget.classList.add('shadow-wide');
      }
    }
  }

  handleLeftArrowKeyPress() {
    this.commonTabsTabOutletElements[this.tabFocusIndex].setAttribute('tabindex', '-1');

    if (this.tabFocusIndex > 0) {
      this.tabFocusIndex -= 1;
      this.commonTabsTabOutletElements[this.tabFocusIndex].setAttribute('tabindex', '0');
      this.commonTabsTabOutletElements[this.tabFocusIndex].focus();
    }
  }

  handleRightArrowKeyPress() {
    this.commonTabsTabOutletElements[this.tabFocusIndex].setAttribute('tabindex', '-1');

    if (this.tabFocusIndex < this.commonTabsTabOutletElements.length - 1) {
      this.tabFocusIndex += 1;
      this.commonTabsTabOutletElements[this.tabFocusIndex].setAttribute('tabindex', '0');
      this.commonTabsTabOutletElements[this.tabFocusIndex].focus();
    }
  }

  handleSpaceKeyPress(e) {
    e.preventDefault();
    this.commonTabsTabOutletElements[this.tabFocusIndex].click();
  }

  handleHomeKeyPress(e) {
    e.preventDefault();
    this.commonTabsTabOutletElements[this.tabFocusIndex].setAttribute('tabindex', '-1');
    this.tabFocusIndex = 0;
    this.commonTabsTabOutletElements[this.tabFocusIndex].setAttribute('tabindex', '0');
    this.commonTabsTabOutletElements[this.tabFocusIndex].focus();
  }

  handleEndKeyPress(e) {
    e.preventDefault();
    this.commonTabsTabOutletElements[this.tabFocusIndex].setAttribute('tabindex', '-1');
    this.tabFocusIndex = this.commonTabsTabOutletElements.length - 1;
    this.commonTabsTabOutletElements[this.tabFocusIndex].setAttribute('tabindex', '0');
    this.commonTabsTabOutletElements[this.tabFocusIndex].focus();
  }

  // Computed properties
  
  isOverflowing() {
    // determine if an overflow is happening
    return this.navTarget.scrollWidth > this.navTarget.offsetWidth;
  }

  activeTabIndex() {
    return this.commonTabsTabOutletElements.findIndex(el => el.getAttribute('aria-selected') === 'true');
  }

  declare readonly commonTabsTabOutlets: CommonTabsTabController[];
  declare readonly commonTabsTabOutletElements: HTMLElement[];
  declare readonly navTarget: HTMLElement;
  declare readonly leftButtonTarget: HTMLElement;
  declare readonly rightButtonTarget: HTMLElement;
  declare tabScrollIndexValue: number;
  declare readonly scrollIntoViewValue: Boolean;
}
