import { Controller } from '@hotwired/stimulus';
import { useHover } from 'stimulus-use';

export default class extends Controller {
  static targets = ['popover', 'hoverable'];

  connect() {
    useHover(this, { element: this.hoverableTarget });
  }

  focusIn() {
    this.show();
  }

  focusOut() {
    this.hide();
  }

  mouseEnter() {
    this.show();
  }

  mouseLeave() {
    this.hide();
  }

  show() {
    // Turbo frame lazy loading requires `display: hidden`, but once it has loaded we don't
    // care if display gets reset to hidden. We can handle fade out with opacity instead.
    this.popoverTarget.classList.remove('hidden');
    this.popoverTarget.classList.remove('invisible');
    this.popoverTarget.classList.add('visible');
    this.popoverTarget.classList.remove('opacity-0');
    this.popoverTarget.classList.add('opacity-100');
    this.hoverableTarget.ariaExpanded = 'true';
  }

  hide() {
    this.popoverTarget.classList.add('invisible');
    this.popoverTarget.classList.remove('visible');
    this.popoverTarget.classList.add('opacity-0');
    this.popoverTarget.classList.remove('opacity-100');
    this.hoverableTarget.ariaExpanded = 'false';
  }

  declare readonly popoverTarget: HTMLElement;
  declare readonly hoverableTarget: HTMLElement;
}
