import { Controller } from '@hotwired/stimulus';
import { interpolateTemplate } from 'javascript/lib/utils';

export default class extends Controller {
  static targets = ['addScoringScaleButton', 'scoringScaleRows', 'scoringScaleRowTemplate', 'naColumn', 'weightColumn', 'percentColumn'];

  static outlets = ['scoring-fields--scoring-scale-row'];

  // eslint-disable-next-line class-methods-use-this
  resetDeleteOptions() {
    const deleteButtons = document.getElementsByClassName('scoring-fields--scoring-scale-row-delete-button');
    if (deleteButtons.length !== 0) {
      if (deleteButtons.length === 1) {
        deleteButtons[0].classList.add('disabled');
      } else {
        deleteButtons[0].classList.remove('disabled');
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  scoringScaleRowsTargetConnected() {
    $('[data-scoring-fields--scoring-scales-target=\'scoringScaleRows\']').sortable({
      handle: '.reorder-handle',
      axis: 'y',
      helper(e, tr) {
        const $originals = tr.children();
        const $helper = tr.clone();
        $helper.children().each((index) => {
          $(this).width($originals.eq(index).width());
        });
        return $helper;
      },
    });

    // Add the required validation for the scoring scale fields.
    jQuery.validator.addClassRules('field-scoring-scale-label-field', {
      trim: true,
      uniqueByClass: ['field-scoring-scale-label-field'],
    });
  }

  resetNullOptionCheckboxes(selectedCheckbox) {
    const checkboxes = document.getElementsByClassName('scoring-fields--scoring-scale-row-null-option');
    Array.prototype.forEach.call(checkboxes, (el) => {
      const checkboxEl = el;
      if (el !== selectedCheckbox.target) {
        checkboxEl.checked = false;
      }
    });

    const weightInputs = document.querySelectorAll('[data-null-option-weight]');

    Array.prototype.forEach.call(weightInputs, (el) => {
      if (el.getAttribute('data-null-option-weight') !== selectedCheckbox.target.id) {
        const weightEl = el;
        weightEl.disabled = false;
        if (el.value === 'N/A') {
          weightEl.type = 'number';
          weightEl.setAttribute('pattern', '[0-9]{1,3}');
          if (weightEl.dataset.previousWeight != null) {
            weightEl.value = weightEl.dataset.previousWeight;
          } else {
            weightEl.value = 0;
          }
        }
      }
    });
    this.resetPercentages();
  }

  resetPercentages() {
    Array.prototype.forEach.call(this.scoringFieldsScoringScaleRowOutlets, (el) => {
      el.updatePercentageFromWeight(el.weightTarget.value);
    });
  }

  getMaxWeight() {
    let max = 0;
    Array.prototype.forEach.call(this.scoringFieldsScoringScaleRowOutlets, (el) => {
      if (parseFloat(el.weightTarget.value) > max) {
        max = parseFloat(el.weightTarget.value);
      }
    });
    return max;
  }

  handleScoringScaleRowChange() {
    this.resetPercentages();

    if (this.scoringScaleRowsTarget.children.length >= 12) {
      this.addScoringScaleButtonTarget.classList.add('hidden');
    } else {
      this.addScoringScaleButtonTarget.classList.remove('hidden');
    }
  }

  addScoringScale() {
    const newIndex = this.scoringScaleRowsTarget.children.length + 1;
    const data = { index: newIndex, label: newIndex };
    const clone = this.scoringScaleRowTemplateTarget.content.firstElementChild.cloneNode(true);
    const node = interpolateTemplate(clone.outerHTML, data);
    this.scoringScaleRowsTarget.insertAdjacentHTML('beforeend', node);
    this.handleScoringScaleRowChange();
    this.resetDeleteOptions();
  }

  handleFieldPropertyChange(e) {
    if (e.target.name.indexOf('[scoring_range_include_na]') > 0) {
      const showNaColumns = (e.target.value === '1');

      if (showNaColumns) {
        this.naColumnTarget.classList.remove('hidden');
        // we have to do this manually because https://github.com/hotwired/stimulus/issues/682
        this.scoringScaleRowTemplateTarget.content.querySelectorAll('td[data-scoring-fields--scoring-scale-row-target=\'naColumn\']')[0].classList.remove('hidden');
      } else {
        this.naColumnTarget.classList.add('hidden');
        this.scoringScaleRowTemplateTarget.content.querySelectorAll('td[data-scoring-fields--scoring-scale-row-target=\'naColumn\']')[0].classList.add('hidden');
      }
      Array.prototype.forEach.call(this.scoringFieldsScoringScaleRowOutlets, (el) => {
        el.updateNaColumnVisibility(showNaColumns);
      });
      this.resetNullOptionCheckboxes(e);
    } else if (e.target.name.indexOf('[uses_scoring]') > 0) {
      const showWeightColumns = (e.target.value === '1');

      if (showWeightColumns) {
        this.weightColumnTarget.classList.remove('hidden');
        this.percentColumnTarget.classList.remove('hidden');
        // we have to do this manually because https://github.com/hotwired/stimulus/issues/682
        this.scoringScaleRowTemplateTarget.content.querySelectorAll('td[data-scoring-fields--scoring-scale-row-target=\'weightColumn\']')[0].classList.remove('hidden');
        this.scoringScaleRowTemplateTarget.content.querySelectorAll('td[data-scoring-fields--scoring-scale-row-target=\'percentColumn\']')[0].classList.remove('hidden');
      } else {
        this.weightColumnTarget.classList.add('hidden');
        this.percentColumnTarget.classList.add('hidden');
        this.scoringScaleRowTemplateTarget.content.querySelectorAll('td[data-scoring-fields--scoring-scale-row-target=\'weightColumn\']')[0].classList.add('hidden');
        this.scoringScaleRowTemplateTarget.content.querySelectorAll('td[data-scoring-fields--scoring-scale-row-target=\'percentColumn\']')[0].classList.add('hidden');
      }
      Array.prototype.forEach.call(this.scoringFieldsScoringScaleRowOutlets, (el) => {
        el.updateWeightColumnVisibility(showWeightColumns);
      });
    } else if (e.target.name.indexOf('[uses_forced_ranking]') > 0) {
      if (e.target.value === '1') {
        // disable NA
        this.naColumnTarget.classList.add('hidden');
        this.scoringScaleRowTemplateTarget.content.querySelectorAll('td[data-scoring-fields--scoring-scale-row-target=\'naColumn\']')[0].classList.add('hidden');
        Array.prototype.forEach.call(this.scoringFieldsScoringScaleRowOutlets, (el) => {
          el.updateNaColumnVisibility(false);
        });
        this.resetNullOptionCheckboxes(e);
      }
    }
  }
}
