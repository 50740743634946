import { Controller } from '@hotwired/stimulus';
import { useHover } from 'stimulus-use';
export default class extends Controller {
  static targets = ['hoverable'];
  static outlets = ['transcription--transcription-display'];

  connect() {
    this.hoverableTargets.forEach((el) => {
      useHover(this, { element: el });
    });
  }

  mouseLeave(e) {
    if (document.querySelector('[data-speakerSelector].visible') != null) {
      document.querySelector('[data-speakerSelector].visible').classList.add('hidden');
      document.querySelector('[data-speakerSelector].visible').classList.remove('visible');
    }
    if (document.querySelector('[data-showAddSpeakerWidget].visible') != null) {
      this.clearAddSpeakerWidgets();
    }
  }

  showSpeakerAttributionOptions(e) {
    const target  = document.querySelector('[data-speakerSelector=\''+e.target.dataset.speaker+'\']');

    if (target.classList.contains('visible')) {
      target.classList.remove('visible');
      target.classList.add('hidden');
    } else {
      this.clearSpeakerSelectors();
      target.classList.remove('hidden');
      target.classList.add('visible');
    }
  }

  // Use freeform text as the new label for this speaker slot
  addNewSpeakerFromText(e) {
    if (e.keyCode === 13 && e.target.value.trim !== '') {
      document.querySelector('[data-speakerlabel="'+e.target.dataset.speaker+'"]').innerText = e.target.value;

      this.transcriptionTranscriptionDisplayOutlet.updateSpeakerWithText(e.target.dataset.speaker, e.target.value);
      this.clearSpeakerSelectors();
    }
  }

  // Selector for speaker
  setSpeakerFromExistingSpeaker(e) {
    // We wil move this value to the one we are swapping it with
    const previousLabel = document.querySelector('[data-speakerlabel="'+e.target.dataset.speaker+'"]').innerText;

    // Update the current label with the new value it will have
    document.querySelector('[data-speakerlabel="'+e.target.dataset.speaker+'"]').innerText = e.target.innerText;

    // Update the label we are swapping from
    document.querySelector('[data-speakerlabel="'+e.target.dataset.newspeaker+'"]').innerText = previousLabel;

    this.transcriptionTranscriptionDisplayOutlet.updateSpeakerWithSpeaker(e.target.dataset.speaker, e.target.dataset.newspeaker);
    this.clearSpeakerSelectors();
  }

  // Selected speaker was a LiveInterviewArchiveJoinableHistory
  setSpeakerFromExistingInterview(e) {
     document.querySelector('[data-speakerlabel="'+e.target.dataset.speaker+'"]').innerText = e.target.innerText;
     this.transcriptionTranscriptionDisplayOutlet.updateSpeakerWithInterview(e.target.dataset.speaker, e.target.dataset.live_interview_archive_joinable_history_id);
     this.clearSpeakerSelectors();
  }

  clearSpeakerSelectors() {
    const speakerSelectors = document.querySelectorAll('[data-speakerSelector]');
    speakerSelectors.forEach((speakerSelector) => {
      speakerSelector.classList.remove('visible');
      speakerSelector.classList.add('hidden');
    });
  }

  // Show the UX to create a new speaker slot
  showAddSpeakerWidget(e) {
    const target  = document.querySelector('[data-showAddSpeakerWidget]');

    if (target.classList.contains('visible')) {
      target.classList.remove('visible');
      target.classList.add('hidden');
    } else {
      this.clearAddSpeakerWidgets();
      target.classList.remove('hidden');
      target.classList.add('visible');
    }
  }

  // Show the UX to create a new speaker slot
  showSpeakerAttributionWidget(e) {
    const target  = document.querySelector('[data-speakerAttributionWidget]');
    target.classList.remove('hidden');
    target.classList.add('visible');
    document.querySelector('[data-modifySpeakerButton]').classList.add('hidden');
  }

  // Hide the UX to create a new speaker slot
  clearAddSpeakerWidgets() {
    const speakerSelectors = document.querySelectorAll('[data-showAddSpeakerWidget]');
    speakerSelectors.forEach((widget) => {
      widget.classList.remove('visible');
      widget.classList.add('hidden');
    });
  }

  // Create a new speaker slot and give it a text label
  addNewSpeakerToTranscriptionRequestFromText(e) {
    if (e.keyCode === 13 && e.target.value.trim !== '') {
      this.transcriptionTranscriptionDisplayOutlet.createSpeakerWithText(e.target.dataset.addspeakertotranscriptionrequestid, e.target.value);
      this.clearAddSpeakerWidgets();
    }
  }

  // Create a new speaker slot and give it LiveInterviewArchiveJoinableHistory association
  addNewSpeakerToTranscriptionRequestFromExistingInterview(e) {
    this.transcriptionTranscriptionDisplayOutlet.createSpeakerWithInterview(e.target.dataset.addspeakertotranscriptionrequestid, e.target.dataset.live_interview_archive_joinable_history_id);
    this.clearAddSpeakerWidgets();
  }
}
