import { Controller } from '@hotwired/stimulus';
import NavbarButtonController from './button_controller';
import { assetPath } from 'javascript/lib/utils';

const vidcruiter = require('./vidcruiter_logo.svg') as string;
const ewitness = require('./ewitness_logo.png') as string;
const vidinsurance = require('./vidinsurance_logo.png') as string;
const vidwitness = require('./vidwitness_logo.svg') as string;

export default class NavbarController extends Controller {
  static outlets = ['navigation--navbar--button'];

  static targets = ['image'];

  static values = { activeSection: String };

  initialize() {
    this.handleTurboClick = this.handleTurboClick.bind(this);
  }

  connect() {
    this.element.addEventListener('turbo:click', this.handleTurboClick);
  }

  disconnect() {
    this.element.removeEventListener('turbo:click', this.handleTurboClick);
  }

  handleTurboClick(event) {
    // only handle turbo clicks for navbar anchor buttons
    if (event.target.dataset['navigation-Navbar-ButtonTarget'] === 'anchor') {
      this.activeSectionValue = event.srcElement.closest('li[data-controller="navigation--navbar--button"]').dataset['navigation-Navbar-ButtonSectionValue'];
    }
  }

  // Update the navbar button classes when the active section is changed
  activeSectionValueChanged() {
    // NOTE: Stimulus bug makes an unnecessary warning show up. Adding a delay forces it to run on the next tick.
    // See: https://github.com/hotwired/stimulus/issues/618
    setTimeout(() => this.updateButtonClasses(), 0);
  }

  updateButtonClasses() {
    if (this.hasNavigationNavbarButtonOutlet
      && this.navigationNavbarButtonOutlets.length
      && !!this.activeSectionValue) {
      this.navigationNavbarButtonOutlets.forEach((button) => {
        button.updateActiveClasses();
      });
    }
  }

  // Set the correct image src based on the current company
  imageTargetConnected() {
    this.imageTarget.classList.remove('hidden');
    if (this.imageTarget.getAttribute('data-current-company-is-ewitness') === 'true') {
      this.imageTarget.setAttribute('src', assetPath(ewitness));
    } else if (this.imageTarget.getAttribute('data-current-company-is-vidinsurance') === 'true') {
      this.imageTarget.setAttribute('src', assetPath(vidinsurance));
    } else if (this.imageTarget.getAttribute('data-current-company-is-vidwitness') === 'true') {
      this.imageTarget.setAttribute('src', assetPath(vidwitness));
    } else {
      this.imageTarget.setAttribute('src', assetPath(vidcruiter));
    }
  }

  declare readonly imageTarget: HTMLImageElement;
  declare activeSectionValue: string;
  declare readonly hasNavigationNavbarButtonOutlet: boolean;
  declare readonly navigationNavbarButtonOutlets: NavbarButtonController[];
}
