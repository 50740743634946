import { Controller } from '@hotwired/stimulus';
import CommonDropdownMenuController from 'components/common/dropdown/menu_controller';
import HistoryTimelineController from './timeline_controller';

type ItemType = 'events' | 'positions' | 'users';

export default class HistoryFiltersBarController extends Controller {
  static targets = ['datepicker', 'datepickerButtonText', 'eventFilterButtonText'];

  static outlets = ['history--timeline', 'common--dropdown--menu'];

  static values = {
    allDatesDefault: String,
    eventFilter: String,
    dateFilter: String,
    positionFilter: String,
    userFilter: String,
  };

  datepickerTargetConnected() {
    const historyFiltersBarController = this;

    $('.jquery-datepicker').datepicker({
      maxDate: new Date(),
      // This is called after a date is selected.
      onSelect(dateText) {
        const selectedTime = $(this).datepicker('getDate').getTime();

        // Get the current day.
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Set initial visible date text
        let visibleDateText = window.moment(new Date(dateText)).format('MMMM D, YYYY');

        // When the day matches clear the value parameter.
        if (selectedTime === today.getTime()) {
          historyFiltersBarController.dateFilterValue = '';
          visibleDateText = historyFiltersBarController.allDatesDefaultValue;
        } else {
          historyFiltersBarController.dateFilterValue = (selectedTime / 1000).toString();
        }

        // Set the caption and refresh the timeline.
        historyFiltersBarController.datepickerButtonTextTarget.textContent = visibleDateText;
        historyFiltersBarController.closeDropdownMenu();
        historyFiltersBarController.updateHistoryTimeline();
      },
    });
  }

  updateFilter(event) {
    // eslint-disable-next-line no-unused-vars
    const [_itemText, itemId]: [string, string | null] = event.params.item;
    const itemType: ItemType = event.params.type;

    switch (itemType) {
      case 'events':
        if (itemId && itemId !== '') {
          this.eventFilterValue = itemId;
        } else {
          this.eventFilterValue = '';
        }
        break;
      case 'positions':
        if (itemId && itemId !== '') {
          this.positionFilterValue = itemId;
        } else {
          this.positionFilterValue = '';
        }
        break;
      case 'users':
        if (itemId && itemId !== '') {
          this.userFilterValue = itemId;
        } else {
          this.userFilterValue = '';
        }
        break;
      default:
        break;
    }

    this.updateHistoryTimeline();
  }

  updateHistoryTimeline() {
    this.historyTimelineOutlet.refresh({
      event: this.eventFilterValue,
      date: this.dateFilterValue,
      position: this.positionFilterValue,
      user: this.userFilterValue,
    });
  }

  closeDropdownMenu() {
    if (this.hasCommonDropdownMenuOutlet) {
      this.commonDropdownMenuOutlets.forEach((el) => {
        el.close();
      });
    }
  }

  declare readonly eventFilterButtonTextTarget: HTMLSpanElement;
  declare readonly datepickerButtonTextTarget: HTMLSpanElement;
  declare readonly datepickerTarget: HTMLElement;
  declare dateFilterValue: string;
  declare eventFilterValue: string;
  declare positionFilterValue: string;
  declare userFilterValue: string;
  declare allDatesDefaultValue: string;

  // history/timeline
  declare historyTimelineOutlet: HistoryTimelineController;

  // common/menu/dropdown
  declare hasCommonDropdownMenuOutlet: boolean;
  declare commonDropdownMenuOutlets: CommonDropdownMenuController[];
}
