import { Controller } from '@hotwired/stimulus';
import { useIntersection, useDebounce } from 'stimulus-use';

export default class extends Controller {
  static targets = ['frame', 'input', 'item', 'noResults'];

  static debounces = ['filter'];

  connect() {
    useIntersection(this);
    useDebounce(this, { wait: 300 });
  }

  appear() {
    if (this.hasFrameTarget) {
      fetch(this.frameSrc);
    }
  }

  filter() {
    const filter = this.inputTarget.value.toLowerCase();

    // Filter list items
    this.itemTargets.forEach((el) => {
      const itemNameKey = el.getAttribute('data-filter-name-key')?.toLowerCase();
      const itemIdKey = el.getAttribute('data-filter-id-key')?.toLowerCase();
      const itemRefKey = el.getAttribute('data-filter-ref-key')?.toLowerCase();

      if (
        itemNameKey?.includes(filter) || itemIdKey?.includes(filter) || itemRefKey?.includes(filter)
      ) {
        el.classList.remove('hidden');
      } else {
        el.classList.add('hidden');
      }
    });

    // If no results, show no result message
    if (this.hasNoResultsTarget) {
      const itemsCount = this.itemTargets.length;
      const hiddenCount = this.itemTargets.filter((el) => el.classList.contains('hidden')).length;
      if (itemsCount === hiddenCount) {
        this.noResultsTarget.classList.remove('hidden');
      } else {
        this.noResultsTarget.classList.add('hidden');
      }
    }
  }

  get frameSrc(): string {
    return this.frameTarget.getAttribute('src') || '';
  }

  declare readonly hasFrameTarget: boolean;
  declare readonly frameTarget: HTMLElement; // Turbo.FrameElement
  declare readonly inputTarget: HTMLInputElement;
  declare readonly itemTarget: HTMLLIElement;
  declare readonly itemTargets: HTMLLIElement[];
  declare readonly noResultsTarget: HTMLInputElement;
  declare readonly hasNoResultsTarget: boolean;
}
