import UtilsRevealContentController from 'components/utils/reveal/content_controller';
import { Controller } from '@hotwired/stimulus';

export default class UtilsRevealRadioController extends Controller {
  static targets = ['radio'];
  static outlets = ['utils--reveal--content'];

  outletName: string | null | undefined;
  radioName: string | null | undefined;

  connect(): void {
    this.outletName = this.element.getAttribute(
      'data-utils--reveal--button-utils--reveal--content-outlet',
    );

    this.radioName = this.element.getAttribute('data-name');

    /* Toggle on connect so state of radio is reflective of state of oulet */
    this.toggle();

    /* We have to use event listeners on the radio group because no change event is fired when radios are unchecked */
    if (document.querySelector(`input[type="radio"][name="${this.radioName}"]`)) {
      document
        .querySelectorAll(`input[type="radio"][name="${this.radioName}"]`)
        .forEach((element) => {
          element.addEventListener('change', () => {
            UtilsRevealRadioController.relatedRadioChanged(this.radioName as string);
          });
        });
    }
  }

  disconnect(): void {
    if (document.querySelector(`input[type="radio"][name="${this.radioName}"]`)) {
      document
        .querySelectorAll(`input[type="radio"][name="${this.radioName}"]`)
        .forEach((element) => {
          /* We can't remove anonymous event listeners, but we can clear it by cloning it */
          let newElement = element.cloneNode(true);
          element.parentNode?.replaceChild(newElement, element);
          // @ts-ignore
          element = newElement;
        });
    }
  }

  static relatedRadioChanged(name: string): void {
    const element = document.querySelector(
      `[data-controller="utils--reveal--radio"][data-name="${name}"]`,
    );

    if (element) {
      const controller = window.Stimulus.getControllerForElementAndIdentifier(
        element,
        'utils--reveal--radio',
      );
      controller.toggle();
    }
  }

  toggle(): void {
    if (!this.hasUtilsRevealContentOutlet) {
      console.error(`Missing outlet "${this.outletName}"`);
      return;
    }

    if (this.radioTarget.checked) {
      this.utilsRevealContentOutlets.forEach((outlet) => {
        outlet.show();
      });
    } else {
      this.utilsRevealContentOutlets.forEach((outlet) => {
        outlet.hide();
      });
    }
  }

  declare radioTarget: HTMLInputElement;
  declare hasUtilsRevealContentOutlet: boolean;
  declare utilsRevealContentOutlets: UtilsRevealContentController[];
}
