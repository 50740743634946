import { Controller } from '@hotwired/stimulus';
import CommonDropdownMenuController from 'components/common/dropdown/menu_controller';
import CommonTabsTabController from 'components/common/tabs/tab_controller';
import { addURLParam, removeURLParam } from '../../javascript/lib/utils';

export default class ProfileVidprofileController extends Controller {
  static outlets = ['common--dropdown--menu', 'common--tabs--tab'];

  static targets = ['label', 'frame', 'noteCount', 'attachmentCount', 'filteredNoteCount', 'filteredAttachmentCount', 'totalNoteCount', 'totalAttachmentCount', 'skeleton', 'collapseButton'];

  static values = {
    personId: Number,
    selectedTab: String,
    businessProcessId: String,
    noteCount: Number,
    attachmentCount: Number,
    totalNoteCount: Number,
    totalAttachmentCount: Number,
  };

  constructor(props) {
    super(props);
    // In order to properly remove the event listener with a bind.
    this.handleTurboStreamEvent = this.handleTurboStreamEvent.bind(this);
  }

  connect() {
    document.addEventListener('turbo:before-stream-render', this.handleTurboStreamEvent);
  }

  disconnect() {
    document.removeEventListener('turbo:before-stream-render', this.handleTurboStreamEvent);
  }

  collapseButtonTargetConnected() {
    this.collapseButtonTarget.addEventListener('click', () => {
      if (decodeURIComponent(document.referrer).includes('trbsrc=/people/')) {
        window.history.back();
      } else {
        window.location.href = '/processes';
      }
    });
  }

  filteredNoteCountTargetConnected() {
    const noteCount = parseInt(this.filteredNoteCountTarget.value);
    if (typeof noteCount === 'number') {
      this.noteCountValue = noteCount;
    }
  }

  filteredAttachmentCountTargetConnected() {
    const attachmentCount = parseInt(this.filteredAttachmentCountTarget.value);
    if (typeof attachmentCount === 'number') {
      this.attachmentCountValue = attachmentCount;
    }
  }

  totalNoteCountTargetConnected() {
    const totalNoteCount = parseInt(this.totalNoteCountTarget.value);
    if (typeof totalNoteCount === 'number') {
      this.totalNoteCountValue = totalNoteCount;
    }
  }

  totalAttachmentCountTargetConnected() {
    const totalAttachmentCount = parseInt(this.totalAttachmentCountTarget.value);
    if (typeof totalAttachmentCount === 'number') {
      this.totalAttachmentCountValue = totalAttachmentCount;
    }
  }

  // Handle Stream Events.
  handleTurboStreamEvent(event) {
    const fallbackToDefaultActions = event.detail.render;

    // Make changes to the render or execute js depending on stream response.
    event.detail.render = (streamElement) => {

      if (streamElement?.target) {
        // Add new tag
        if (streamElement.target.includes('vidprofile_tag_stack')) {
          const isSuccesfull = streamElement.getAttribute('data-success');
          const isError = streamElement.getAttribute('data-error');

          if (isSuccesfull === 'true') {
            this.showSuccessMessage(streamElement);
          }
          else if(isError){
            this.showErrorMessage(streamElement);
          }
        }

        // Notes
        if (streamElement.target.includes('vidprofile_notes_') || streamElement.target.includes('vidprofile_person_note_')) {
          const isError = streamElement.getAttribute('data-error');
          if(isError){
            this.showErrorMessage(streamElement);
          }else{
            if (streamElement.action !== 'replace') {
              this.noteCountValue += streamElement.action === 'remove' ? -1 : 1;
              this.totalNoteCountValue += streamElement.action === 'remove' ? -1 : 1;
              this.noteCountTarget.innerText = `${this.noteCountValue}`;
              if (streamElement.action !== 'remove') this.showSuccessMessage(streamElement);
            } else if (streamElement.action === 'replace') {
              this.showSuccessMessage(streamElement);
            }
          }
        }

        // Attachments
        if (streamElement.target.includes('vidprofile_profile_attachments_') || streamElement.target.includes('profile_card_person_attachment_')) {
          const isError = streamElement.getAttribute('data-error');
          if(isError){
            this.showErrorMessage(streamElement);
          }else{
            if (streamElement.action !== 'replace') {
              this.attachmentCountValue += streamElement.action === 'remove' ? -1 : 1;
              this.totalAttachmentCountValue += streamElement.action === 'remove' ? -1 : 1;
              this.attachmentCountTarget.innerText = `${this.attachmentCountValue}`;
            }
            if (streamElement.action !== 'remove') this.showSuccessMessage(streamElement);
          }
        }

      }

      fallbackToDefaultActions(streamElement);
    };
  }

  totalNoteCountValueChanged() {
    if (this.hasNoteCountValue && this.hasTotalNoteCountValue) {
      this.noteCountTarget.innerHTML = ` - ${this.noteCountValue}/${this.totalNoteCountValue}`;
    }
  }

  noteCountValueChanged() {
    if (this.hasNoteCountValue && this.hasTotalNoteCountValue) {
      this.noteCountTarget.innerHTML = ` - ${this.noteCountValue}/${this.totalNoteCountValue}`;
    }
  }

  totalAttachmentCountValueChanged() {
    if (this.hasAttachmentCountValue && this.hasTotalAttachmentCountValue) {
      this.attachmentCountTarget.innerHTML = ` - ${this.attachmentCountValue}/${this.totalAttachmentCountValue}`;
    }
  }

  attachmentCountValueChanged() {
    if (this.hasAttachmentCountValue && this.hasTotalAttachmentCountValue) {
      this.attachmentCountTarget.innerHTML = ` - ${this.attachmentCountValue}/${this.totalAttachmentCountValue}`;
    }
  }

  showSuccessMessage(element: HTMLElement) {
    const title = element.getAttribute('data-title');
    const content = element.getAttribute('data-content');
    window.showSuccessMessage(title, content);
    window.closeOpenDialogs();
  }

  showErrorMessage(element: HTMLElement) {
    const title = element.getAttribute('data-title');
    const content = element.getAttribute('data-error');
    window.showErrorMessage(title, content);
    window.closeOpenDialogs();
  }

  frameTargetConnected() {
    this.setFrameSrc(this.personIdValue, this.selectedTabValue, this.businessProcessIdValue);
    this.updatePositionSelectorLabel(this.findProcessNameFromId(this.businessProcessIdValue));
  }

  setFrameSrc(personId, tab, processIds) {
    if (this.hasFrameTarget) {
      this.frameTarget.innerHTML = this.skeletonTarget.innerHTML;
      this.frameTarget.src = `/people/${personId}/related_positions?vidprofile_tab=${tab}&vidprofile_process_ids=${String(processIds)}`;
    }
  }

  selectTab(event) {
    const tab = event?.params?.tab;
    if (this.hasCommonTabsTabOutlet) {
      this.commonTabsTabOutlets.forEach((el) => {
        const tabType = el.context.scope.element.getAttribute('data-tab');
        if (tabType === tab || tabType === 'positions') {
          el.select();
        }
      });
    }
    if (tab) {
      addURLParam('vidprofile_tab', tab);
    }
    else {
      removeURLParam('vidprofile_tab');
    }
    this.selectedTabValue = tab || '';
    this.setFrameSrc(this.personIdValue, this.selectedTabValue, this.businessProcessIdValue);
  }

  findProcessNameFromId(id) {
    const els = document.querySelectorAll(`[data-profile--vidprofile-business-process-id-param]`);
    let name = '??';
    if (els.length > 0) {
      els.forEach((el) => {
        const idValue = el.getAttribute('data-profile--vidprofile-business-process-id-param');
        if (String(idValue) === id) {
          name = el.getAttribute('data-profile--vidprofile-business-process-name-param') || '??';
        }
      });
    }
    return name;
  }

  updatePositionSelectorLabel(value) {
    if (this.hasLabelTarget) {
      this.labelTarget.innerHTML = value;
    }
  }

  selectPosition(event) {

    this.updatePositionSelectorLabel(event.params.businessProcessName);
    this.businessProcessIdValue = event.params.businessProcessId;

    if (this.businessProcessIdValue) {
      addURLParam('vidprofile_process_ids', this.businessProcessIdValue);
    } else {
      removeURLParam('vidprofile_process_ids');
    }

    // Set Frame Src.
    this.setFrameSrc(this.personIdValue, this.selectedTabValue, this.businessProcessIdValue);

    // Close dropdown
    if (this.hasCommonDropdownMenuOutlet) {
      this.commonDropdownMenuOutlets.forEach((el) => {
        el.close();
      });
    }
  }

  // Targets
  declare readonly labelTarget: HTMLElement;
  declare readonly frameTarget: HTMLIFrameElement;
  declare readonly noteCountTarget: HTMLElement;
  declare readonly attachmentCountTarget: HTMLElement;
  declare readonly filteredNoteCountTarget: HTMLInputElement;
  declare readonly totalNoteCountTarget: HTMLInputElement;
  declare readonly filteredAttachmentCountTarget: HTMLInputElement;
  declare readonly totalAttachmentCountTarget: HTMLInputElement;
  declare readonly hasLabelTarget: boolean;
  declare readonly hasFrameTarget: boolean;
  declare readonly hasNoteCountTarget: boolean;
  declare readonly hasTotalNoteCountTarget: boolean;
  declare readonly hasTotalAttachmentCountTarget: boolean;
  declare readonly skeletonTarget: HTMLElement;
  declare readonly collapseButtonTarget: HTMLElement;

  // Values
  declare selectedTabValue: String;
  declare readonly personIdValue: Number;
  declare businessProcessIdValue: String;
  declare noteCountValue: number;
  declare attachmentCountValue: number;
  declare totalNoteCountValue: number;
  declare totalAttachmentCountValue: number;
  declare readonly hasNoteCountValue: boolean;
  declare readonly hasAttachmentCountValue: boolean;
  declare readonly hasTotalNoteCountValue: boolean;
  declare readonly hasTotalAttachmentCountValue: boolean;

  // Outlets
  declare hasCommonDropdownMenuOutlet: boolean;
  declare commonDropdownMenuOutlets: CommonDropdownMenuController[];

  declare hasCommonTabsTabOutlet: boolean;
  declare commonTabsTabOutlets: CommonTabsTabController[];

}
