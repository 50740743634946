import { Controller } from '@hotwired/stimulus';
import CommonTabsTabsController from './tabs_controller';
import CommonTabsFrameController from './frame_controller';

export default class CommonTabsTabController extends Controller {
  static outlets = ['common--tabs--tabs', 'common--tabs--frame'];

  static classes = ['default', 'selected'];

  static values = { useTurboStream: Boolean };

  select() {
    // Deselect all tabs
    this.commonTabsTabsOutlet.deselectAllTabs();

    // Select this tab
    this.addSelectedClasses(this.element);

    // Add aria attributes
    this.ariaSelect(this.element);
  }

  selectByFrame() {
    this.select();

    // show/hide the appropriate frames
    const frameId = this.element.getAttribute('data-frame');
    if (frameId && this.hasCommonTabsFrameOutlet) {
      this.commonTabsFrameOutlets.forEach((frame) => {
        if (frame.element.id === frameId) {
          // eslint-disable-next-line no-param-reassign
          frame.activeValue = true;
        } else {
          // eslint-disable-next-line no-param-reassign
          frame.activeValue = false;
        }
      });
    }
  }

  async selectByStream() {
    this.select();

    // show/hide the appropriate frames
    await this.fetchStreamResults();
  }

  async fetchStreamResults() {
    const href = this.element.getAttribute('href');

    if (href) {
      await fetch(href, {
        headers: {
          'Content-Type': 'text/vnd.turbo-stream.html',
        },
      }).then((response) => response.text())
        .then((html) => window.Turbo.renderStreamMessage(html));
    }
  }

  deselect() {
    this.removeSelectedClasses(this.element);
    this.ariaDeselect(this.element);
  }

  addSelectedClasses(element) {
    element.classList.remove(...this.defaultClasses);
    element.classList.add(...this.selectedClasses);
  }

  removeSelectedClasses(element) {
    element.classList.remove(...this.selectedClasses);
    element.classList.add(...this.defaultClasses);
  }

  ariaSelect(element) {
    element.setAttribute('aria-selected', 'true');
    element.setAttribute('tabindex', '0');
    element.focus();
  }

  ariaDeselect(element) {
    element.setAttribute('aria-selected', 'false');
    element.setAttribute('tabindex', '-1');
    element.blur();
  }

  declare readonly commonTabsTabsOutlet: CommonTabsTabsController;
  declare readonly commonTabsFrameOutlet: CommonTabsFrameController;
  declare readonly commonTabsFrameOutlets: CommonTabsFrameController[];
  declare readonly hasCommonTabsFrameOutlet: Boolean;
  declare readonly defaultClasses: String[];
  declare readonly selectedClasses: String[];
  declare useTurboStreamValue: Boolean;
  declare readonly hasUseTurboStreamValue: Boolean;
}
